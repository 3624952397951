import {Component, Inject, OnInit} from '@angular/core';
import {POLYMORPHEUS_CONTEXT} from "@tinkoff/ng-polymorpheus";
import {TuiDialogContext} from "@taiga-ui/core";
import {ISaleModel} from "@models/sale.model";
import {ILineModel} from "@models/line.model";
import {IPaymentIntentModel} from "@models/payment-intent.model";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

export type RefundIntents = {id: string, amount: number};

@Component({
  selector: 'app-refund-dialog',
  templateUrl: './refund-dialog.component.html',
  styleUrls: ['./refund-dialog.component.scss']
})
export class RefundDialogComponent implements OnInit {
  public sale?: ISaleModel;
  public intents?: IPaymentIntentModel[];
  public totalRefunded = 0;

  public refund: number = 0;

  public selectedLines: ILineModel[] = [];

  public validationForm?: FormGroup;

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<RefundIntents[] | undefined>,
    private formBuilder: FormBuilder,
  ) {}

  public ngOnInit(): void {
    const data: any | undefined = this.context.data;

    if (data) {
      this.sale = data['sale'];
      this.intents = data['intents'];

      this.totalRefunded = this.intents?.map(i => i.refunded_amount).reduce((a,b) => a+b, 0) ?? 0;

      this.validationForm = this.formBuilder.group({
        input: ['',
          [
            Validators.required,
            Validators.max((this.sale?.total_amount ?? 100) / 100),
            Validators.min(1),
          ]
        ]
      });
    }
  }

  public didClickLine(line: ILineModel): void {
    const index = this.selectedLines.findIndex(l => l.id === line.id);

    if (index === -1) {
      this.selectedLines.push(line);
    } else {
      this.selectedLines = this.selectedLines.filter(l => l.id !== line.id);
    }

    this.refund = this.selectedLines.map(l => l.total_amount).reduce((a,b) => a + b, 0) / 100;
    this.validationForm?.get('input')?.setValue(this.refund);
    this.validationForm?.get('input')?.markAllAsTouched();
  }

  public didClickRefund(): void {
    if (this.intents?.length === 0) {
      return;
    }

    if (this.validationForm?.invalid) {
      return;
    }

    this.refund = this.validationForm?.get('input')?.value ?? 0;

    if (this.refund * 100 > (this.sale?.total_amount ?? 0 - this.totalRefunded)) {
      return;
    }

    const refundIntents = this.calculateRefunds();

    if (refundIntents.length === 0) {
      return;
    }

    this.context.completeWith(refundIntents);
  }

  private calculateRefunds(): RefundIntents[] {
    const refundedIntents: RefundIntents[] = [];
    let refundAmount = this.refund * 100;

    this.intents?.forEach(intent => {
      if (refundAmount <= 0 || intent.amount <= 0) {
        return; // Skip negative amounts to avoid errors and invalid refunds.
      }

      const refundableAmount = Math.min(refundAmount, intent.amount - intent.refunded_amount);

      if (refundableAmount > 0) {
        refundedIntents.push({ id: intent.id, amount: refundableAmount });
        refundAmount -= refundableAmount;
      }
    });

    return refundedIntents;
  }
}
