import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "@env/environment";
import {ICreateBookingRequestModel} from "@models/request/create-booking.request.model";
import {ICalendarModel} from "@models/calendar.model";
import {ICalendarBookingModel} from "@models/calendar-booking.model";
import {IUpdateBookingRequestModel} from "@models/request/update-booking.request.model";
import {IBookingSettingsModel} from "@models/booking-settings.model";
import {IUpdateBookingSettingsRequestModel} from "@models/request/update-booking-settings.request.model";
import {PaginationHolder} from "@models/pagination.model";
import {ICreateBlockingRequestModel} from "@models/request/create-blocking.request.model";
import {IUpdateBlockingRequestModel} from "@models/request/update-blocking.request.model";
import {IBookingOverlapModel} from "@models/booking-overlap.model";
import {IUserModel} from "@models/user.model";
import {IWaitlistModel} from "@models/waitlist.model";

@Injectable({
	providedIn: 'root',
})
export class BookingService {
	constructor(
		private http: HttpClient,
	) {}

  public fetchBookingSettings(): Observable<IBookingSettingsModel> {
    return this.http.get<IBookingSettingsModel>(`${environment.api_url}/bookings/settings`);
  }

  public updateBookingSettings(reqModel: IUpdateBookingSettingsRequestModel): Observable<IBookingSettingsModel> {
    return this.http.patch<IBookingSettingsModel>(`${environment.api_url}/bookings/settings`, reqModel);
  }

	public createBooking(reqModel: ICreateBookingRequestModel): Observable<any> {
		return this.http.post<any>(`${environment.api_url}/bookings`, reqModel);
	}

	public updateBooking(booking_id: string, reqModel: IUpdateBookingRequestModel): Observable<any> {
		return this.http.patch<any>(`${environment.api_url}/bookings/${booking_id}`, reqModel);
	}

	public fetchCalendarBookings(from_date: string, to_date: string, isWeekView: boolean, calendarUser?: IUserModel): Observable<ICalendarModel[]> {
    const params: any = {
      from_date,
      to_date,
    }

    if (isWeekView && calendarUser) {
      params.user_id = calendarUser.id;
    }

		return this.http.get<ICalendarModel[]>(`${environment.api_url}/bookings/calendar`, { params });
	}

  public fetchBooking(id: string): Observable<ICalendarBookingModel> {
    return this.http.get<ICalendarBookingModel>(`${environment.api_url}/bookings/${id}`);
  }

  public deleteBooking(id: string): Observable<ICalendarBookingModel> {
    return this.http.delete<ICalendarBookingModel>(`${environment.api_url}/bookings/${id}`);
  }

  public createBlocking(reqData: ICreateBlockingRequestModel): Observable<any> {
    return this.http.post<any>(`${environment.api_url}/time-blockings`, reqData);
  }

  public updateBlocking(id: string, reqData: IUpdateBlockingRequestModel): Observable<any> {
    return this.http.patch<any>(`${environment.api_url}/time-blockings/${id}`, reqData);
  }

  public deleteBlocking(id: string): Observable<any> {
    return this.http.delete<any>(`${environment.api_url}/time-blockings/${id}`);
  }

  public fetchBookings(from_date: string, to_date: string, user_id: string): Observable<PaginationHolder<ICalendarBookingModel>> {
    return this.http.get<PaginationHolder<ICalendarBookingModel>>(`${environment.api_url}/bookings`, {
      params: {
        from_date,
        to_date,
        user_id
      }
    });
  }

  public fetchCustomerBookings(customer_id: string): Observable<PaginationHolder<ICalendarBookingModel>> {
    return this.http.get<PaginationHolder<ICalendarBookingModel>>(`${environment.api_url}/bookings/`, {
      params: {
        customer_id,
      }
    });
  }

  public checkForOverlap(from_date: string, to_date: string, user_id: string, booking_id?: string, time_blocking_id?: string): Observable<IBookingOverlapModel> {
    const queryParams: any = {
      from_date,
      to_date,
      user_id,
    };

    if (time_blocking_id) {
      queryParams.time_blocking_id = time_blocking_id;
    }

    if (booking_id) {
      queryParams.booking_id = booking_id;
    }

    return this.http.get<IBookingOverlapModel>(`${environment.api_url}/bookings/calendar/availability`, {
      params: queryParams
    });
  }

  public fetchWaitlist(): Observable<PaginationHolder<IWaitlistModel>> {
    return this.http.get<PaginationHolder<IWaitlistModel>>(`${environment.api_url}/waiting-list/details`);
  }
}
