import { IBillingSettingsModel } from '@models/billing-settings.model';
import { IInvoiceModel } from '@models/invoice.model';

export interface IBillingState {
  isLoading: boolean;
  billingSettings?: IBillingSettingsModel;
  invoices: IInvoiceModel[];
}

export const initialBillingState: IBillingState = {
  isLoading: false,
  invoices: [],
};
