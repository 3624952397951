import { IRootState } from "@app/core/store/root.state";
import { createSelector } from "@ngrx/store";
import {IAuthenticationState} from "@app/core/store/authentication/authentication.state";

const _selectAuth = (state: IRootState) => state.authentication;

export const selectAuthIsLoading = createSelector(_selectAuth, (state: IAuthenticationState) => state.isLoading);
export const selectAccounts = createSelector(_selectAuth, (state: IAuthenticationState) => state.accounts);
export const selectAccount = createSelector(_selectAuth, (state: IAuthenticationState) => state.account);
export const selectAccountSettings = createSelector(_selectAuth, (state: IAuthenticationState) => state.settings);
export const selectAuthUser = createSelector(_selectAuth, (state: IAuthenticationState) => state.user);

export const selectUsers = createSelector(_selectAuth, (state: IAuthenticationState) => state.users);
export const selectUsersCursor = createSelector(_selectAuth, (state: IAuthenticationState) => state.usersCursor);

export const selectOnboardingSettings = createSelector(_selectAuth, (state: IAuthenticationState) => state.isOnboardingSettingsCompleted);
export const selectOnboardingLink = createSelector(_selectAuth, (state: IAuthenticationState) => state.isOnboardingLinkCompleted);
export const selectOnboardingCompleted = createSelector(_selectAuth, (state: IAuthenticationState) => state.isOnboardingCompleted);
