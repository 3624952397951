import {Component, Input, OnInit} from '@angular/core';
import {IUserModel} from "@models/user.model";

export enum EAvatarSize {
	EXTRA_SMALL = 24,
	SMALL = 40,
	MEDIUM = 55,
	BIG = 80,
}

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
})
export class UserAvatarComponent implements OnInit {
	@Input() public user?: IUserModel;
  @Input() public customer_name?: string;
	@Input() public size: EAvatarSize = EAvatarSize.MEDIUM;
	@Input() public selected: boolean = false;
	@Input() public showInitials: boolean = true;

	public EAvatarSize = EAvatarSize;

  constructor() { }

  public ngOnInit(): void {}

}
