import {EffectsModule} from "@ngrx/effects";
import {CommonModule} from "@angular/common";
import {StoreModule} from "@ngrx/store";
import {UploadEffects} from "@core/store/upload/upload.effects";
import {NgModule} from "@angular/core";
import {uploadReducers} from "@core/store/upload/upload.reducers";

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('upload', uploadReducers),
        EffectsModule.forFeature([UploadEffects]),
    ],
    providers: [UploadEffects],
})
export class UploadStoreModule {
}
