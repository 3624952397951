export class Language {
	constructor(
		public languageName: string,
		public languageKey: string,
		public rebrandIcon: string,
		public momentKey: string,
		public angularKey: string,
		public browserMatch: string[],
		public stripeKey: string,
		public supportKey: string
	) {}
}

export interface ILanguageService {
	getCurrentLanguage(): Language;
}
