import produce from 'immer';
import { EErrorActions, ErrorActions } from '@app/core/store/error/error.actions';
import { IBillingState, initialBillingState } from '@app/core/store/billing/billing.state';
import { BillingActions, EBillingActions } from '@app/core/store/billing/billing.actions';

export const billingReducers = (state: IBillingState = initialBillingState, action: BillingActions | ErrorActions): IBillingState => {
  let nextState: IBillingState;

  switch (action.type) {
    // Add Credit Card From Token

    case EBillingActions.AddCreditCardFromToken:
      nextState = produce(state, draftState => {
        draftState.isLoading = true;
      });

      return nextState;
    case EBillingActions.AddCreditCardFromTokenSuccess:
      nextState = produce(state, draftState => {
        draftState.isLoading = false;
      });

      return nextState;

    // Fetch Billing Settings

    case EBillingActions.FetchBillingSettings:
      nextState = produce(state, draftState => {
        draftState.isLoading = true;
      });

      return nextState;
    case EBillingActions.FetchBillingSettingsSuccess:
      nextState = produce(state, draftState => {
        draftState.isLoading = false;
        draftState.billingSettings = action.settings;
      });

      return nextState;

    // Fetch Invoices

    case EBillingActions.FetchInvoices:
      nextState = produce(state, draftState => {
        draftState.isLoading = true;
      });

      return nextState;
    case EBillingActions.FetchInvoicesSuccess:
      nextState = produce(state, draftState => {
        draftState.isLoading = false;
        draftState.invoices = action.invoices;
      });

      return nextState;

    // ERROR

    case EErrorActions.LoadError:
      nextState = produce(state, draftState => {
        draftState.isLoading = false;
      });

      return nextState;
    default:
      return state;
  }
};
