import {PaginationHolder} from "@models/pagination.model";
import {ICategoryModel} from "@models/category.model";
import {IProductModel} from "@models/product.model";

export interface ICatalogState {
	isLoading: boolean;
	categories: PaginationHolder<ICategoryModel>;
	products: PaginationHolder<IProductModel>;
  categoryProducts: PaginationHolder<IProductModel>;
  selectedCategoryId: string;
}

export const initialCatalogState: ICatalogState = {
	isLoading: false,
	categories: null,
	products: null,
  categoryProducts: null,
  selectedCategoryId: null,
}
