import {AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {CommonModule} from "@angular/common";
import {ModalController} from "@ionic/angular";
import {Store} from "@ngrx/store";
import {IRootState} from "@core/store/root.state";
import {selectAccountSettings} from "@core/store/authentication/authentication.selectors";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {IAccountSettingsModel} from "@models/account-settings.model";
import {LogContentService} from "@app/shared/components/log-content/services/log-content.service";
import {ELogLevel} from "@app/shared/components/log-content/enums/log-level.enum";
import {TuiDialogContext} from "@taiga-ui/core";
import {POLYMORPHEUS_CONTEXT} from '@tinkoff/ng-polymorpheus';

@UntilDestroy()
@Component({
  selector: 'app-pincode',
  templateUrl: './pincode.component.html',
  styleUrls: ['./pincode.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
  ]
})
export class PinCodeComponent implements OnInit, AfterViewInit {
  @ViewChild('pin1') public pin1?: ElementRef;
  @ViewChild('pin2') public pin2?: ElementRef;
  @ViewChild('pin3') public pin3?: ElementRef;
  @ViewChild('pin4') public pin4?: ElementRef;

  private settings?: IAccountSettingsModel;

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<string | undefined>,
    private store: Store<IRootState>,
    private modalController: ModalController,
    private logService: LogContentService,

  ) {}

  public ngOnInit(): void {
    this.$settings.pipe(untilDestroyed(this)).subscribe(data => {
      if (!data) {
        return;
      }

      this.settings = data;
    });
  }

  public ngAfterViewInit(): void {
    this.pin1?.nativeElement.focus();
  }

  public didEnterPinPart(event: any): void {
    if (event.inputType === 'deleteContentBackward') {
      if (event.target === this.pin2?.nativeElement) {
        this.pin1?.nativeElement.focus();
      } else if (event.target === this.pin3?.nativeElement) {
        this.pin2?.nativeElement.focus();
      } else if (event.target === this.pin4?.nativeElement) {
        this.pin3?.nativeElement.focus();
      }

      return;
    }

    if (event.target === this.pin1?.nativeElement) {
      this.pin2?.nativeElement.focus();
    } else if (event.target === this.pin2?.nativeElement) {
      this.pin3?.nativeElement.focus();
    } else if (event.target === this.pin3?.nativeElement) {
      this.pin4?.nativeElement.focus();
    } else if (event.target === this.pin4?.nativeElement) {
      event.target.blur();
      this.didEnterPinCode();
    }
  }

  public didEnterPinCode(): void {
    const pinCode = `${this.pin1?.nativeElement.value}${this.pin2?.nativeElement.value}${this.pin3?.nativeElement.value}${this.pin4?.nativeElement.value}`;

    if (pinCode === this.settings?.pin_code) {
      this.context.completeWith(pinCode);

      this.logService.logContent({
        level: ELogLevel.SUCCESS,
        description: 'Skiftet til administrator tilstand',
      });
    } else {
      if (this.pin1 && this.pin2 && this.pin3 && this.pin4) {
        this.pin1.nativeElement.value = '';
        this.pin2.nativeElement.value = '';
        this.pin3.nativeElement.value = '';
        this.pin4.nativeElement.value = '';
      }

      this.logService.logContent({
        level: ELogLevel.WARNING,
        description: 'Forkert pinkode',
      });
    }
  }

  public didClickCancel(): void {
    this.context.completeWith(undefined);
  }

  protected readonly $settings = this.store.select(selectAccountSettings);
}
