import {Injectable} from "@angular/core";
import {ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {Store} from "@ngrx/store";
import {IRootState} from "@core/store/root.state";
import {getCurrentState} from "@app/functions";

@Injectable()
export class RoleGuard implements CanActivate {
  public constructor(
    private store: Store<IRootState>,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const isAdminMode = getCurrentState(this.store).navigation.isAdminMode;

      if (isAdminMode) {
        return resolve(true);
      }

      this.router.navigate(['/portal/bookings']);

      return resolve(false);
    });
  }
}
