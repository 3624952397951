<div [@changeVisibility]="currentState" class="log-content-container">
	<div class="log-content-block">
		<log-content-box
			[description]="description"
			[cssClass]="cssClass"
			[closeAction]="includeCloseAction ? closeAction : null"
			(closeActionClicked)="closeSelf()"
		></log-content-box>
	</div>
</div>
