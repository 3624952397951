import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Action, Store} from "@ngrx/store";
import {IRootState} from "@core/store/root.state";
import {catchError, mergeMap} from "rxjs/operators";
import {from, of} from "rxjs";
import {LoadError} from "@core/store/error/error.actions";
import {
  CreatePaymentIntent,
  CreatePaymentIntentSuccess,
  EPaymentActions,
  FetchPaymentIntents,
  FetchPaymentIntentsSuccess,
  RefundPayment,
  RefundPaymentSuccess,
  SendCustomerReceipt,
  SendCustomerReceiptSuccess,
} from "@core/store/payment/payment.actions";
import {PaymentService} from "@core/store/payment/payment.service";
import {FetchOrder} from "@core/store/order/order.actions";
import {FetchSaleById} from "@core/store/economy/economy.actions";

@Injectable()
export class PaymentEffects {
  constructor(
    private actions$: Actions,
    private store: Store<IRootState>,
    private paymentService: PaymentService,
  ) {}

  public onCreatePaymentIntent = createEffect(() => this.actions$.pipe(ofType<CreatePaymentIntent>(EPaymentActions.CreatePaymentIntent),
    mergeMap((action) => from(this.paymentService.createPaymentIntent(action.reqModel, action.order_id)).pipe(
      mergeMap((intent) => {
        const actions: Action[] = [new FetchOrder(action.order_id), new CreatePaymentIntentSuccess()];

        if (action.receiptEmail?.length > 0) {
          actions.push(new SendCustomerReceipt(action.order_id, action.receiptEmail));
        }

        return actions;
      }),
      catchError((error) => of(new LoadError(error, action)))
    ))
  ));

  public onFetchPaymentIntents = createEffect(() => this.actions$.pipe(ofType<FetchPaymentIntents>(EPaymentActions.FetchPaymentIntents),
    mergeMap((action) => from(this.paymentService.getPaymentIntents(action.order_id)).pipe(
      mergeMap((data) => [new FetchPaymentIntentsSuccess(data)]),
      catchError((error) => of(new LoadError(error, action)))
    ))
  ));

  public onSendCustomerReceipt = createEffect(() => this.actions$.pipe(ofType<SendCustomerReceipt>(EPaymentActions.SendCustomerReceipt),
    mergeMap((action) => from(this.paymentService.sendCustomerReceipt(action.order_id, action.receiptEmail)).pipe(
      mergeMap(() => [new SendCustomerReceiptSuccess()]),
      catchError((error) => of(new LoadError(error, action)))
    ))
  ));

  public onRefundPayment = createEffect(() => this.actions$.pipe(ofType<RefundPayment>(EPaymentActions.RefundPayment),
    mergeMap((action) => from(this.paymentService.createRefunds(action.id, action.intents)).pipe(
      mergeMap(() => [new RefundPaymentSuccess(), new FetchSaleById(action.id), new FetchPaymentIntents(action.id)]),
      catchError((error) => of(new LoadError(error, action)))
    ))
  ));
}
