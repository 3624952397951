import {IChartModel} from "@models/chart.model";
import {ISMSUsageModel} from "@models/sms-usage.model";
import {IOverviewStatsModel} from "@models/overview-stats.model";

export interface IStatisticsState {
  isLoading: boolean;
  dashboardRevenue?: IChartModel;
  chainRevenue?: IChartModel;
  smsUsage?: ISMSUsageModel;
  overviewStats?: IOverviewStatsModel;
  statisticsDateStart: string;
  statisticsDateEnd: string;
}

export const initialStatisticsState: IStatisticsState = {
  isLoading: false,
  statisticsDateStart: new Date().toISOString(),
  statisticsDateEnd: new Date().toISOString(),
}
