import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {StoreModule} from "@ngrx/store";
import {EffectsModule} from "@ngrx/effects";
import {bookingReducers} from "@core/store/booking/booking.reducers";
import {BookingEffects} from "@core/store/booking/booking.effects";

@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature('booking', bookingReducers),
		EffectsModule.forFeature([BookingEffects])],
	providers: [BookingEffects],
})
export class BookingStoreModule {}
