import {NgModule} from "@angular/core";
import {LocalCurrencyPipe} from "@app/shared/pipes/local-currency/local-currency.pipe";
import {CommonModule} from "@angular/common";

@NgModule({
  declarations: [LocalCurrencyPipe],
  imports: [CommonModule],
  exports: [LocalCurrencyPipe],
})
export class LocalCurrencyModule {}
