import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {CustomerFilterPipe} from "@app/modules/portal/pages/customers/pipes/customer-filter.pipe";

@NgModule({
  declarations: [
    CustomerFilterPipe,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    CustomerFilterPipe,
  ]
})
export class CustomerFilterModule { }
