import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { metaReducers, rootReducers } from '@app/core/store/root.reducers';
import { getInitialState } from '@app/core/store/root.state';
import { rootEffects } from '@app/core/store/root.effects';
import { ErrorStoreModule } from '@app/core/store/error/error.module';
import { AuthenticationStoreModule } from '@core/store/authentication/authentication.store.module';
import { NavigationStoreModule } from '@core/store/navigation/navigation-store.module';
import { BookingStoreModule } from '@core/store/booking/booking.store.module';
import { CatalogStoreModule } from '@core/store/catalog/catalog.store.module';
import { UploadStoreModule } from '@core/store/upload/upload-store.module';
import { OrderStoreModule } from '@core/store/order/order-store.module';
import { PaymentStoreModule } from '@core/store/payment/payment-store.module';
import { SettingsStoreModule } from '@core/store/settings/settings-store.module';
import { CustomerStoreModule } from '@core/store/customer/customer-store.module';
import { EconomyStoreModule } from '@core/store/economy/economy-store.module';
import { StatisticsStoreModule } from '@core/store/statistics/statistics-store.module';
import { VoucherStoreModule } from '@core/store/voucher/voucher-store.module';
import { BillingStoreModule } from '@core/store/billing/billing-store.module';

@NgModule({
  imports: [
    NavigationStoreModule,
    AuthenticationStoreModule,
    BookingStoreModule,
    ErrorStoreModule,
    CatalogStoreModule,
    UploadStoreModule,
    OrderStoreModule,
    PaymentStoreModule,
    SettingsStoreModule,
    CustomerStoreModule,
    EconomyStoreModule,
    StatisticsStoreModule,
    VoucherStoreModule,
    BillingStoreModule,
    StoreModule.forRoot(rootReducers, { initialState: getInitialState(), metaReducers }),
    EffectsModule.forRoot(rootEffects),
  ],
})
export class RootStoreModule {}
