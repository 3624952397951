import { Injectable } from '@angular/core';
import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor as HttpInterceptorInterface,
	HttpRequest,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { IRootState } from "@app/core/store/root.state";
import { IUserModel } from "@app/core/models/user.model";
import {getCurrentState} from "@app/functions";

@Injectable()
export class HttpInterceptor implements HttpInterceptorInterface {
	constructor(private _store: Store<IRootState>) {}

	public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const authenticatedUser: IUserModel = getCurrentState(this._store).authentication.user;

		let token = null;
		if (authenticatedUser) {
			token = authenticatedUser.token;
		}

		if (token && !req.url.includes('amazonaws.com/file-upload-intents')) {
			req = req.clone({
				setHeaders: {
					'Authorization': 'Bearer ' + token,
				},
			});
		}

		return next.handle(req).pipe(
			tap(
				(event: HttpEvent<any>) => {},
				(err: any) => {
					if (err instanceof HttpErrorResponse) {
						if (err.status === 401) {
							// if (authenticatedUser && authenticatedTenant) {
							// 	this.store.dispatch(new RefreshToken(authenticatedUser, authenticatedTenant.id));
							// } else {
							// 	this.store.dispatch(new LogoutUser());
							// }
						}
					}
				}
			)
		);
	}
}
