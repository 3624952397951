import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {StoreModule} from "@ngrx/store";
import {EffectsModule} from "@ngrx/effects";
import {catalogReducers} from "@core/store/catalog/catalog.reducers";
import {CatalogEffects} from "@core/store/catalog/catalog.effects";

@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature('catalog', catalogReducers),
		EffectsModule.forFeature([CatalogEffects])],
	providers: [CatalogEffects],
})
export class CatalogStoreModule {}
