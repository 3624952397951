import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ILogContentAction } from "@app/shared/components/log-content/interfaces/log-content.interface";
import {ELogLevel} from "@app/shared/components/log-content/enums/log-level.enum";

@Component({
	selector: 'log-content-box',
	templateUrl: './log-content-box.component.html',
	styleUrls: ['./log-content-box.component.scss'],
})
export class LogContentBoxComponent {
	@Input() public title: string = '';
	@Input() public description: string = null;
	@Input() public cssClass: string = '';
	@Input() public actions: ILogContentAction[] = [];
	@Input() public closeAction: ILogContentAction = null;

	@Output() public closeActionClicked: EventEmitter<ILogContentAction> = new EventEmitter();
	@Output() public actionClicked: EventEmitter<ILogContentAction> = new EventEmitter();
  protected readonly ELogLevel = ELogLevel;
}
