import {IReportModel} from "@models/report.model";
import {PaginationHolder} from "@models/pagination.model";
import {IPaymentMethodModel} from "@models/payment-method.model";
import {ISaleModel} from "@models/sale.model";

export interface IEconomyState {
  isLoading: boolean;
  report?: IReportModel;
  paymentMethods?: PaginationHolder<IPaymentMethodModel>,
  sales?: PaginationHolder<ISaleModel>,
}

export const initialEconomyState: IEconomyState = {
  isLoading: false,
}
