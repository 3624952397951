import produce from "immer";
import {EErrorActions, ErrorActions} from "@app/core/store/error/error.actions";
import {IPaymentState, initialPaymentState} from "@app/core/store/payment/payment.state";
import {PaymentActions, EPaymentActions} from "@app/core/store/payment/payment.actions";

export const paymentReducers = (state: IPaymentState = initialPaymentState, action: PaymentActions | ErrorActions): IPaymentState => {
  let nextState;

  switch (action.type) {
    // Create Payment Intent

    case EPaymentActions.CreatePaymentIntent:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = true;
      });

      return nextState;
    case EPaymentActions.CreatePaymentIntentSuccess:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = false;
      });

      return nextState;

    // Fetch Payment Intents

    case EPaymentActions.FetchPaymentIntents:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = true;
      });

      return nextState;
    case EPaymentActions.FetchPaymentIntentsSuccess:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = false;
        draftState.paymentIntents = action.intents;
      });

      return nextState;

    // Send Customer Receipt Email

    case EPaymentActions.SendCustomerReceipt:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = true;
      });

      return nextState;
    case EPaymentActions.SendCustomerReceiptSuccess:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = false;
      });

      return nextState;

    // Refund Payment

    case EPaymentActions.RefundPayment:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = true;
      });

      return nextState;
    case EPaymentActions.RefundPaymentSuccess:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = false;
      });

      return nextState;


    // ERROR

    case EErrorActions.LoadError:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = false;
      });

      return nextState;
    default:
      return state;
  }
};

