import produce from "immer";
import {EErrorActions, ErrorActions} from "@app/core/store/error/error.actions";
import {ICustomerState, initialCustomerState} from "@app/core/store/customer/customer.state";
import {CustomerActions, ECustomerActions} from "@app/core/store/customer/customer.actions";
import {BookingActions, EBookingActions} from "@core/store/booking/booking.actions";

export const customerReducers = (state: ICustomerState = initialCustomerState, action: CustomerActions | BookingActions | ErrorActions): ICustomerState => {
  let nextState;

  switch (action.type) {
    // Fetch Customer

    case ECustomerActions.FetchCustomers:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = true;
      });

      return nextState;

    case ECustomerActions.FetchCustomersSuccess:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = false;

        if (action.customers.meta.current_page > 1) {
          if (!draftState.customers?.data) {
            draftState.customers = action.customers;
          } else {
            draftState.customers.data = draftState.customers.data.concat(action.customers.data);
            draftState.customers.meta = action.customers.meta;
          }
        } else {
          draftState.customers = action.customers;
        }

      });

      return nextState;

      // Create Customer

    case ECustomerActions.CreateCustomer:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = true;
      });

      return nextState;

    case ECustomerActions.CreateCustomerSuccess:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = false;
        draftState.createdCustomer = action.customer;
      });

      return nextState;

    // Update Customer

    case ECustomerActions.UpdateCustomer:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = true;
      });

      return nextState;

    case ECustomerActions.UpdateCustomerSuccess:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = false;
      });

      return nextState;

    // Fetch Customer By Id

    case ECustomerActions.FetchCustomerById:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = true;
      });

      return nextState;

    case ECustomerActions.FetchCustomerByIdSuccess:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = false;
        draftState.currentCustomer = action.customer;
      });

      return nextState;

    // Fetch Customer Journal

    case ECustomerActions.FetchCustomerJournal:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = true;
      });

      return nextState;

    case ECustomerActions.FetchCustomerJournalSuccess:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = false;
        draftState.customerJournal = action.journal;
      });

      return nextState;

    // Create Customer Journal Entry

    case ECustomerActions.CreateCustomerJournal:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = true;
      });

      return nextState;

    case ECustomerActions.CreateCustomerJournalSuccess:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = false;
      });

      return nextState;

    // Fetch Customer SMS History

    case ECustomerActions.FetchCustomerSMSHistory:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = true;
      });

      return nextState;

    case ECustomerActions.FetchCustomerSMSHistorySuccess:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = false;
        draftState.customerSMSHistory = action.data;
      });

      return nextState;

    // Send Customer SMS

    case ECustomerActions.SendCustomerSMS:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = true;
      });

      return nextState;

    case ECustomerActions.SendCustomerSMSSuccess:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = false;
      });

      return nextState;

    // Create Booking Success Cleanup

    case EBookingActions.CreateBookingSuccess:
      nextState = produce(state, (draftState) => {
        draftState.createdCustomer = null;
      });

      return nextState;

    // ERROR

    case EErrorActions.LoadError:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = false;
      });

      return nextState;
    default:
      return state;
  }
};

