import { EEnvironment } from '@app/core/enums/environment.enum';

export const environment = {
  env: EEnvironment.production,
  hash: new Date().getTime(),
  api_url: 'https://api.bodil.io',
  sentryVersionPrefix: 'prod',
  sentryDsn: 'https://97305fa85ca74cd8a54cab13cac886db@o4505550248607744.ingest.sentry.io/4505550253326336',
  stripe: 'pk_live_51NfUimB2GYCRNxP7T5rqGzUxiIQ9trD2MGl7ENUlIe31RkMobzJCdw1xODI3lsSPlI5edGddFkLTz5TvFOxTyno700EJ49bEs1',
  pusher: {
    key: 'd8bf4f00dac7815aafe1',
    cluster: 'eu',
  },
};
