import { Component, OnInit } from '@angular/core';
import {EAvatarSize} from "@app/shared/components/user-avatar/user-avatar.component";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {NavigationEnd, Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {IRootState} from "@core/store/root.state";
import {selectAccount} from "@core/store/authentication/authentication.selectors";
import {Logout} from "@core/store/authentication/authentication.actions";
import {selectSidebarVisibility} from "@core/store/settings/settings.selectors";
import {SetSidebarVisibility} from "@core/store/settings/settings.actions";
import {DialogService} from "@services/dialog/dialog.service";
import {selectIsAdminMode} from "@core/store/navigation/navigation.selectors";
import {SetIsAdminMode} from "@core/store/navigation/navigation.actions";
import {Meta} from "@angular/platform-browser";

@UntilDestroy()
@Component({
  selector: 'app-portal-menu',
  templateUrl: './portal-menu.component.html',
  styleUrls: ['./portal-menu.component.scss']
})
export class PortalMenuComponent implements OnInit {
	public showMenu = true;
  public isAdminMode = true;

	public currentRoute: string = 'home';

	public items: MenuItem[] = [
		{title: 'Dashboard', route: 'dashboard', image: 'icon-dashboard', isAdminOnly: true, comingSoon: false},
		{title: 'Bookings', route: 'bookings', image: 'icon-calendar-active', isAdminOnly: false, comingSoon: false},
		{title: 'Kunder', route: 'customers', image: 'icon-customer', isAdminOnly: false, comingSoon: false},
		{title: 'Katalog', route: 'catalog', image: 'icon-order', isAdminOnly: false, comingSoon: false},
		{title: 'Statistik', route: 'statistics', image: 'icon-graph', isAdminOnly: true, comingSoon: false},
    {title: 'Økonomi', route: 'economy', image: 'icon-economy', isAdminOnly: true, comingSoon: false},
    {title: 'Assistant', route: 'assistant', image: 'icon-assistant', isAdminOnly: true, comingSoon: true},
	];

  constructor(
    private meta: Meta,
		private _store: Store<IRootState>,
		private _router: Router,
		private dialogService: DialogService,
	) { }

  public ngOnInit(): void {
    this.meta.updateTag({content: '#F9FAFB'}, 'name=theme-color');
		this.currentRoute = this._router.url.split('/')[2];

		this._router.events.pipe(untilDestroyed(this)).subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.currentRoute = this._router.url.split('/')[2];
			}
		});

    this.$sidebarVisibility.pipe(untilDestroyed(this)).subscribe(isVisible => {
      this.showMenu = isVisible;
    });

    this.$isAdminMode.pipe(untilDestroyed(this)).subscribe(isAdminMode => {
      this.isAdminMode = isAdminMode;
    });
  }

	public changeRoute(path: string): void {
		this._router.navigate(['/portal/' + path])
	}

  public didClickLogOut(): void {
    this._store.dispatch(new Logout());
  }

  public toggleSideBarVisibility(): void {
    this.showMenu = !this.showMenu;
    this._store.dispatch(new SetSidebarVisibility(this.showMenu));
  }

  public didClickOpenHelp(): void {
    window.open('https://bodil.io/help', '_blank').focus();
  }

  public async didClickLock(): Promise<void> {
    if (this.isAdminMode) {
      const changeView = await this.dialogService.showMessage(
        'Skift til medarbejdervisning?',
        'Når du skifter til medarbejdervisning, bliver funktionerne til administrering skjulte. Du skal indtaste din PIN-kode for at få adgang til administrering igen.',
        'Skift',
      );

      if (!changeView) {
        return
      }

      this._store.dispatch(new SetIsAdminMode(false));
    } else {
      const confirmed = await this.dialogService.showPinCode();

      if (!confirmed) {
        return;
      }

      this._store.dispatch(new SetIsAdminMode(true));
    }
  }

  protected readonly $account = this._store.select(selectAccount);
  protected readonly $sidebarVisibility = this._store.select(selectSidebarVisibility);
  protected readonly $isAdminMode = this._store.select(selectIsAdminMode);
  protected readonly EAvatarSize = EAvatarSize;
}

export type MenuItem = {title: string, route: string, image: string, isAdminOnly: boolean, comingSoon: boolean};
