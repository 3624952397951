import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IRootState } from '@core/store/root.state';
import { catchError, mergeMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { LoadError } from '@core/store/error/error.actions';
import {
  ChangeBookingHours,
  ESettingsActions,
  FetchBookingHours,
  FetchBookingHoursSuccess,
  FetchSMSPrice,
  FetchSMSPriceSuccess,
  FetchSMSSettings,
  FetchSMSSettingsSuccess,
  FetchSMSTemplates,
  FetchSMSTemplatesSuccess,
  UpdateSMSSettings,
  UpdateSMSSettingsSuccess,
  UpdateSMSTemplate,
  UpdateSMSTemplateSuccess,
} from '@core/store/settings/settings.actions';
import { SettingsService } from '@core/store/settings/settings.service';
import { LogContentService } from '@app/shared/components/log-content/services/log-content.service';
import { ELogLevel } from '@app/shared/components/log-content/enums/log-level.enum';

@Injectable()
export class SettingsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<IRootState>,
    private settingsService: SettingsService,
    private logContent: LogContentService
  ) {}

  public onFetchBookingHours = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchBookingHours>(ESettingsActions.FetchBookingHours),
      mergeMap(action =>
        from(this.settingsService.getBookingHours()).pipe(
          mergeMap(hours => [new FetchBookingHoursSuccess(hours)]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onChangeBookingHours = createEffect(() =>
    this.actions$.pipe(
      ofType<ChangeBookingHours>(ESettingsActions.ChangeBookingHours),
      mergeMap(action =>
        from(this.settingsService.postBookingHours(action.toCreate)).pipe(
          mergeMap(() =>
            from(this.settingsService.patchBookingHours(action.toUpdate)).pipe(
              mergeMap(() =>
                from(this.settingsService.deleteBookingHours(action.toDelete)).pipe(
                  mergeMap(() => [new FetchBookingHours()]),
                  catchError(error => of(new LoadError(error, action)))
                )
              ),
              catchError(error => of(new LoadError(error, action)))
            )
          ),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onFetchSMSSettings = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchSMSSettings>(ESettingsActions.FetchSMSSettings),
      mergeMap(action =>
        from(this.settingsService.getSMSSettings()).pipe(
          mergeMap(settings => [new FetchSMSSettingsSuccess(settings)]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onFetchSMSPrice = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchSMSPrice>(ESettingsActions.FetchSMSPrice),
      mergeMap(action =>
        from(this.settingsService.getSMSPrice()).pipe(
          mergeMap(price => [new FetchSMSPriceSuccess()]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onUpdateSMSSettings = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateSMSSettings>(ESettingsActions.UpdateSMSSettings),
      mergeMap(action =>
        from(this.settingsService.patchSMSSettings(action.reqData)).pipe(
          mergeMap(() => [new UpdateSMSSettingsSuccess(), new FetchSMSSettings()]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onFetchSMSTemplates = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchSMSTemplates>(ESettingsActions.FetchSMSTemplates),
      mergeMap(action =>
        from(this.settingsService.getSMSTemplates()).pipe(
          mergeMap(templates => [new FetchSMSTemplatesSuccess(templates)]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onUpdateSMSTemplates = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateSMSTemplate>(ESettingsActions.UpdateSMSTemplate),
      mergeMap(action =>
        from(this.settingsService.patchSMSTemplates(action.id, action.reqData)).pipe(
          mergeMap(() => {
            this.logContent.logContent({
              level: ELogLevel.SUCCESS,
              description: 'SMS opdateret',
            });

            return [new UpdateSMSTemplateSuccess(), new FetchSMSTemplates()];
          }),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );
}
