import {EErrorActions, ErrorActions} from "@core/store/error/error.actions";
import produce from "immer";
import {IBookingState, initialBookingState} from "@core/store/booking/booking.state";
import {BookingActions, EBookingActions} from "@core/store/booking/booking.actions";

export const bookingReducers = (state: IBookingState = initialBookingState, action: BookingActions | ErrorActions): IBookingState => {
	let nextState;

	switch (action.type) {

    // Fetch Booking Settings

    case EBookingActions.FetchBookingSettings:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = true;
      });

      return nextState;
    case EBookingActions.FetchBookingSettingsSuccess:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = false;
        draftState.settings = action.bookingSettings;
      });

      return nextState;

    // Update Booking Settings

    case EBookingActions.UpdateBookingSettings:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = true;
      });

      return nextState;
    case EBookingActions.UpdateBookingSettingsSuccess:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = false;
        draftState.settings = action.bookingSettings;
      });

      return nextState;

		// Create Booking

		case EBookingActions.CreateBooking:
			nextState = produce(state, (draftState) => {
				draftState.isLoading = true;
			});

			return nextState;
		case EBookingActions.CreateBookingSuccess:
			nextState = produce(state, (draftState) => {
				draftState.isLoading = false;
			});

			return nextState;

		// Update Booking

		case EBookingActions.UpdateBooking:
			nextState = produce(state, (draftState) => {
				draftState.isLoading = true;
				draftState.calendar.forEach(cUser => {
					const index = cUser.bookings.findIndex(booking => booking.id === action.booking_id);
				})
			});

			return nextState;
		case EBookingActions.UpdateBookingSuccess:
			nextState = produce(state, (draftState) => {
				draftState.isLoading = false;
			});

			return nextState;

    // Check Overlap
    case EBookingActions.CheckOverlap:
      nextState = produce(state, (draftState) => {
        draftState.isLoadingOverlap = true;
      });

      return nextState;

    case EBookingActions.CheckOverlapSuccess:
      nextState = produce(state, (draftState) => {
        draftState.isLoadingOverlap = false;
        draftState.overlap = action.overlap;
      });

      return nextState;

    case EBookingActions.ClearOverlap:
      nextState = produce(state, (draftState) => {
        draftState.isLoadingOverlap = false;
        draftState.overlap = undefined;
      });

      return nextState;

    // Delete Booking

    case EBookingActions.DeleteBooking:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = true;
      });

      return nextState;
    case EBookingActions.DeleteBookingSuccess:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = false;
      });

      return nextState;

    // Create Blocking

    case EBookingActions.CreateBlocking:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = true;
      });

      return nextState;
    case EBookingActions.CreateBlockingSuccess:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = false;
      });

      return nextState;

    // Update Blocking

    case EBookingActions.UpdateBlocking:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = true;
      });

      return nextState;
    case EBookingActions.UpdateBlockingSuccess:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = false;
      });

      return nextState;

		// Fetch Calendar Bookings

		case EBookingActions.FetchCalendarBookings:
			nextState = produce(state, (draftState) => {
				draftState.isLoading = true;
			});

			return nextState;
		case EBookingActions.FetchCalendarBookingsSuccess:
			nextState = produce(state, (draftState) => {
				draftState.isLoading = false;
				draftState.calendar = action.calendar;
			});

			return nextState;

    // Fetch Calendar Bookings

    case EBookingActions.FetchBookingById:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = true;
      });

      return nextState;
    case EBookingActions.FetchBookingByIdSuccess:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = false;
        draftState.currentBooking = action.booking;
      });

      return nextState;

    // Fetch Customer Bookings

    case EBookingActions.FetchCustomerBookings:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = true;
      });

      return nextState;
    case EBookingActions.FetchCustomerBookingsSuccess:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = false;
        draftState.customerBookings = action.bookings;
      });

      return nextState;

		// Set Current Date

		case EBookingActions.SetCurrentDate:
			nextState = produce(state, (draftState) => {
				draftState.currentDate = action.date;
			});

			return nextState;

    // Set Is Week View

    case EBookingActions.SetIsWeekView:
      nextState = produce(state, (draftState) => {
        draftState.isWeekView = action.isWeekView;
      });

      return nextState;

    // Set Calendar User

    case EBookingActions.SetCalendarUser:
      nextState = produce(state, (draftState) => {
        draftState.selectedCalendarUser = action.user;
      });

      return nextState;

    // Fetch Waitlist

    case EBookingActions.FetchWaitlist:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = true;
      });

      return nextState;

    case EBookingActions.FetchWaitlistSuccess:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = false;
        draftState.waitlist = action.data;
      });

      return nextState;

		// ERROR

		case EErrorActions.LoadError:
			nextState = produce(state, (draftState) => {
				draftState.isLoading = false;
			});

			return nextState;
		default:
			return state;
	}
};
