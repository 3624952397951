import {Action} from "@ngrx/store";
import {IChartModel} from "@models/chart.model";
import {ISMSUsageModel} from "@models/sms-usage.model";
import {IOverviewStatsModel} from "@models/overview-stats.model";

export enum EStatisticsActions {
  GetDashboardRevenue = '[STATISTICS] Get Dashboard Revenue',
  GetDashboardRevenueSuccess = '[STATISTICS] Get Dashboard Revenue Success',

  FetchSMSUsage = '[STATISTICS] Fetch SMS Usage',
  FetchSMSUsageSuccess = '[STATISTICS] Fetch SMS Usage Success',

  UpdateStatisticsDateRange = '[STATISTICS] Update Statistics Date Range',

  FetchOverviewStatistics = '[STATISTICS] Fetch Dashboard Stats',
  FetchOverviewStatisticsSuccess = '[STATISTICS] Fetch Dashboard Stats Success',

  FetchChainRevenueChart = '[STATISTICS] Fetch Chain Revenue Chart',
  FetchChainRevenueChartSuccess = '[STATISTICS] Fetch Chain Revenue Chart Success',
}

export class GetDashboardRevenue implements Action {
  public readonly type = EStatisticsActions.GetDashboardRevenue;

  constructor(public from_date: string, public to_date: string, public group_by?: string | undefined) {}
}

export class GetDashboardRevenueSuccess implements Action {
  public readonly type = EStatisticsActions.GetDashboardRevenueSuccess;

  constructor(public data: IChartModel) {}
}

// Fetch SMS Usage

export class FetchSMSUsage implements Action {
  public readonly type = EStatisticsActions.FetchSMSUsage;

  constructor(public from_date: string, public to_date: string) {}
}

export class FetchSMSUsageSuccess implements Action {
  public readonly type = EStatisticsActions.FetchSMSUsageSuccess;

  constructor(public usage: ISMSUsageModel) {}
}

// Update Statistics Date Range

export class UpdateStatisticsDateRange implements Action {
  public readonly type = EStatisticsActions.UpdateStatisticsDateRange;

  constructor(public from_date: string, public to_date: string) {}
}

// Fetch Dashboard Stats

export class FetchOverviewStatistics implements Action {
  public readonly type = EStatisticsActions.FetchOverviewStatistics;

  constructor(public from_date: string, public to_date: string, public group_by: string) {}
}

export class FetchOverviewStatisticsSuccess implements Action {
  public readonly type = EStatisticsActions.FetchOverviewStatisticsSuccess;

  constructor(public stats: IOverviewStatsModel) {}
}

// Fetch Chain Revenue Chart

export class FetchChainRevenueChart implements Action {
  public readonly type = EStatisticsActions.FetchChainRevenueChart;

  constructor(public from_date: string, public to_date: string, public group_by?: string | undefined) {}
}

export class FetchChainRevenueChartSuccess implements Action {
  public readonly type = EStatisticsActions.FetchChainRevenueChartSuccess;

  constructor(public data: IChartModel) {}
}


export type StatisticsActions =
  GetDashboardRevenue | GetDashboardRevenueSuccess |
  UpdateStatisticsDateRange |
  FetchSMSUsage | FetchSMSUsageSuccess |
  FetchOverviewStatistics | FetchOverviewStatisticsSuccess |
  FetchChainRevenueChart | FetchChainRevenueChartSuccess;
