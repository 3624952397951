import {Action} from "@ngrx/store";
import {ICreatePaymentIntentRequestModel} from "@models/request/create-payment-intent.request.model";
import {IPaymentIntentModel} from "@models/payment-intent.model";
import {PaginationHolder} from "@models/pagination.model";
import {RefundIntents} from "@app/modules/portal/pages/economy/pages/sales/components/refund-dialog/refund-dialog.component";

export enum EPaymentActions {
  CreatePaymentIntent = '[PAYMENT] Create Payment Intent',
  CreatePaymentIntentSuccess = '[PAYMENT] Create Payment Intent Success',

  FetchPaymentIntents = '[PAYMENT] Fetch Payment Intents',
  FetchPaymentIntentsSuccess = '[PAYMENT] Fetch Payment Intents Success',

  SendCustomerReceipt = '[PAYMENT] Send Customer Receipt',
  SendCustomerReceiptSuccess = '[PAYMENT] Send Customer Receipt Success',

  RefundPayment = '[PAYMENT] Refund Payment',
  RefundPaymentSuccess = '[PAYMENT] Refund Payment Success',
}

// Create Payment Intent

export class CreatePaymentIntent implements Action {
  public readonly type = EPaymentActions.CreatePaymentIntent;

  constructor(public order_id: string, public reqModel: ICreatePaymentIntentRequestModel, public receiptEmail?: string) {}
}

export class CreatePaymentIntentSuccess implements Action {
  public readonly type = EPaymentActions.CreatePaymentIntentSuccess;

  constructor() {}
}

// Fetch Payment Intents

export class FetchPaymentIntents implements Action {
  public readonly type = EPaymentActions.FetchPaymentIntents;

  constructor(public order_id: string) {}
}

export class FetchPaymentIntentsSuccess implements Action {
  public readonly type = EPaymentActions.FetchPaymentIntentsSuccess;

  constructor(public intents: PaginationHolder<IPaymentIntentModel>) {}
}

// Send Customer Receipt

export class SendCustomerReceipt implements Action {
  public readonly type = EPaymentActions.SendCustomerReceipt;

  constructor(public order_id: string, public receiptEmail?: string) {}
}

export class SendCustomerReceiptSuccess implements Action {
  public readonly type = EPaymentActions.SendCustomerReceiptSuccess;

  constructor() {}
}

// Refund Payment

export class RefundPayment implements Action {
  public readonly type = EPaymentActions.RefundPayment;

  constructor(public id: string, public intents: RefundIntents[]) {}
}

export class RefundPaymentSuccess implements Action {
  public readonly type = EPaymentActions.RefundPaymentSuccess;

  constructor() {}
}

export type PaymentActions =
  CreatePaymentIntent | CreatePaymentIntentSuccess |
  FetchPaymentIntents | FetchPaymentIntentsSuccess |
  SendCustomerReceipt | SendCustomerReceiptSuccess |
  RefundPayment | RefundPaymentSuccess;
