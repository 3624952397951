import {IRootState} from "@core/store/root.state";
import {Store} from "@ngrx/store";
import {take} from "rxjs/operators";

export function getCurrentState(aStore: Store<IRootState>): IRootState {
	let state: IRootState;

	aStore.pipe(take(1)).subscribe((s) => (state = s));

	if (!state) {
		throw new Error('No state? Something went very wrong!');
	}

	return state;
}

export function randomString(length: number): string {
  const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';

  for (let i = 0; i < length; i++ ) {
    result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
  }

  return result;
}
