<div class="container">
  <!-- Menu -->
  <div
    class="hidden sm:flex flex-col items-center justify-between {{
      showMenu ? 'lg:max-w-[272px] lg:w-[272px] lg:items-start' : ''
    }} w-[90px] h-full pt-8 pb-10 px-4 gap-10 menu-gradiant print:hidden relative overflow-y-scroll">
    <div class="flex w-full flex-col gap-10">
      <!-- Logo	-->
      <div class="h-6 {{ !showMenu ? '' : 'lg:block' }} hidden">
        <img class="h-full" src="assets/logo/logo-white.svg" alt="icon" />
      </div>

      <div class="h-8 {{ showMenu ? 'lg:hidden' : '' }} block">
        <img class="mx-auto h-full" src="assets/logo/bodil-head-logo.svg" alt="icon" />
      </div>

      <div class="absolute left-0 top-0 z-0 h-full">
        <svg width="100%" height="100%" fill="none" viewBox="0 0 315 1024" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M312.88-25C190.547 148.333-33.3201 501 49.8799 525c104.0001 30-248.9999-563 0 559"
            stroke="#181C20"
            stroke-width="4"
            stroke-linejoin="round" />
        </svg>
      </div>

      <div
        (click)="toggleSideBarVisibility()"
        class="absolute right-0 top-8 hidden w-fit cursor-pointer rounded-bl-md rounded-tl-md bg-brand-gray-800 px-2 py-4 duration-300 hover:bg-brand-dark-gray lg:block">
        <img
          class="svg-fill-white duration-500 transition-all {{ showMenu ? 'rotate-0' : 'rotate-180' }}"
          src="assets/icon/chevron-left.svg" />
      </div>

      <!--	Menu	-->
      <div class="z-10 flex h-full flex-col">
        <div
          *ngFor="let menuItem of items | isAdminOnly: isAdminMode"
          class="menu-item {{ currentRoute.includes(menuItem.route) ? 'bg-brand-gray-800' : '' }}"
          (click)="changeRoute(menuItem.route)">
          <i
            class="{{ menuItem.image }} !w-5 !h-5 {{
              currentRoute.includes(menuItem.route) ? 'bg-brand-lilac-400' : 'bg-brand-gray-400'
            }}"></i>
          <p
            class="{{ currentRoute.includes(menuItem.route) ? 'text-brand-lilac-400' : 'text-brand-gray-400' }} font-medium {{
              showMenu ? 'lg:block' : ''
            }} hidden">
            {{ menuItem.title }}
          </p>

          <div
            *ngIf="menuItem.comingSoon"
            [tuiHint]="tooltip"
            class="{{ showMenu ? 'lg:block' : '' }} hidden relative bg-brand-gray-800 rounded-full px-2.5 py-1 right-0">
            <img src="assets/icon/lock.svg" />
          </div>
        </div>
      </div>

      <ng-template #tooltip>
        <div class="font-bold">Kommer snart!</div>
      </ng-template>
    </div>

    <div class="flex w-full flex-col gap-10">
      <!--	Usage	-->
      <div class="z-10 flex flex-col gap-4">
        <!--	Buttons	-->
        <div class="flex flex-wrap {{ showMenu ? 'lg:flex-nowrap' : '' }} gap-4">
          <!--	Settings -->
          <div
            *ngIf="isAdminMode"
            tuiHint="Indstillinger"
            tuiHintDirection="right"
            [tuiHintShowDelay]="10"
            [tuiHintHideDelay]="10"
            (click)="changeRoute('settings')"
            class="flex cursor-pointer items-center justify-center rounded bg-brand-dark-gray p-3.5 !ring-brand-gray-600 duration-300 hover:ring-2">
            <img
              class="w-5 h-5 {{ currentRoute.includes('settings') ? 'svg-fill-lilac-400' : 'svg-fill-gray-400' }}"
              src="assets/icon/settings.svg"
              alt="icon" />
          </div>

          <!--	Help -->
          <div
            (click)="didClickOpenHelp()"
            tuiHint="Hjælp"
            tuiHintDirection="right"
            [tuiHintShowDelay]="10"
            [tuiHintHideDelay]="10"
            class="flex cursor-pointer items-center justify-center rounded bg-brand-dark-gray p-3.5 !ring-brand-gray-600 duration-300 hover:ring-2">
            <img
              class="w-5 h-5 {{ currentRoute.includes('help') ? 'svg-fill-lilac-400' : 'svg-fill-gray-400' }}"
              src="assets/icon/help.svg"
              alt="icon" />
          </div>

          <!--	Lock -->
          <div
            tuiHint="{{ isAdminMode ? 'Lås' : 'Lås op' }}"
            tuiHintDirection="right"
            [tuiHintShowDelay]="10"
            [tuiHintHideDelay]="10"
            (click)="didClickLock()"
            class="flex cursor-pointer items-center justify-center rounded bg-brand-dark-gray p-3.5 !ring-brand-gray-600 duration-300 hover:ring-2">
            <img *ngIf="isAdminMode" class="svg-fill-gray-400 h-5 w-5" src="assets/icon/unlocked.svg" alt="icon" />
            <img *ngIf="!isAdminMode" class="svg-fill-gray-400 h-5 w-5" src="assets/icon/locked.svg" alt="icon" />
          </div>
        </div>
      </div>

      <!--	User	-->
      <div class="z-10 flex items-center gap-2" *ifLet="$account | async as account">
        <div class="h-12 w-12 overflow-hidden rounded-lg">
          <img class="h-full w-full object-cover" *ngIf="account?.image_url" [src]="account?.image_url" />
          <img class="h-full w-full object-cover" src="assets/img/default-avatar.jpg" *ngIf="!account?.image_url" />
        </div>

        <div
          routerLink="/select-chain"
          class="{{ showMenu ? 'lg:flex' : '' }} flex-col hidden hover:bg-brand-gray-600 p-2 rounded-lg cursor-pointer duration-300">
          <div class="flex gap-1">
            <p class="font-bold text-white">{{ account?.name ?? '..' }}</p>
            <img src="assets/icon/arrows.svg" alt="icon" />
          </div>

          <p class="font-bold text-brand-gray-400">{{ account?.address.city ?? '..' }}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="z-20 mb-[60px] w-full overflow-hidden rounded-b-xl bg-brand-gray-50 sm:mb-0 sm:mt-2 sm:rounded-l-xl">
    <router-outlet></router-outlet>
  </div>

  <div class="absolute bottom-0 flex h-[60px] w-full justify-between bg-brand-dark print:hidden sm:hidden">
    <div *ngIf="isAdminMode" class="menu-item w-full justify-center" (click)="changeRoute('dashboard')">
      <svg
        class="{{ currentRoute === 'dashboard' ? 'fill-brand-lilac-400' : 'fill-brand-gray-400' }}"
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none">
        <g clip-path="url(#a)">
          <path
            d="M0 1.667C0 .747.746 0 1.667 0h4.666C7.253 0 8 .746 8 1.667v7.666C8 10.253 7.254 11 6.333 11H1.667C.747 11 0 10.254 0 9.333V1.667ZM0 15.667C0 14.747.746 14 1.667 14h4.666C7.253 14 8 14.746 8 15.667v2.666C8 19.253 7.254 20 6.333 20H1.667C.747 20 0 19.254 0 18.333v-2.666ZM12 10.667C12 9.747 12.746 9 13.667 9h4.666c.92 0 1.667.746 1.667 1.667v7.666c0 .92-.746 1.667-1.667 1.667h-4.666c-.92 0-1.667-.746-1.667-1.667v-7.666ZM12 1.667C12 .747 12.746 0 13.667 0h4.666C19.253 0 20 .746 20 1.667v2.666C20 5.253 19.254 6 18.333 6h-4.666C12.747 6 12 5.254 12 4.333V1.667Z" />
        </g>
        <defs>
          <clipPath id="a"><path fill="#fff" d="M0 0h20v20H0z" /></clipPath>
        </defs>
      </svg>
    </div>
    <div class="menu-item w-full justify-center" (click)="changeRoute('bookings')">
      <svg
        class="{{ currentRoute === 'bookings' ? 'fill-brand-lilac-400' : 'fill-brand-gray-400' }}"
        width="20"
        height="20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#a)">
          <path
            d="M17 3h-1.25V1.25a1.25 1.25 0 0 0-2.5 0V3h-6.5V1.25a1.25 1.25 0 0 0-2.5 0V3H3a3 3 0 0 0-3 3v11a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V6a3 3 0 0 0-3-3Zm-2.616 7.384-5 5a1.2507 1.2507 0 0 1-1.768 0l-2-2a1.2502 1.2502 0 0 1 1.768-1.768L8.5 12.732l4.116-4.116a1.2502 1.2502 0 0 1 1.768 1.768Z" />
        </g>
        <defs>
          <clipPath id="a"><path fill="#fff" d="M0 0h20v20H0z" /></clipPath>
        </defs>
      </svg>
    </div>
    <div class="menu-item w-full justify-center" (click)="changeRoute('customers')">
      <svg
        class="{{ currentRoute === 'customers' ? 'fill-brand-lilac-400' : 'fill-brand-gray-400' }}"
        width="20"
        height="20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#a)">
          <path
            d="M10 0a10 10 0 1 0 10 10A10.0115 10.0115 0 0 0 10 0Zm0 4a3 3 0 1 1 0 5.9998A3 3 0 0 1 10 4Zm5.162 12.094a7.9422 7.9422 0 0 1-10.328 0 .504.504 0 0 1-.054-.714 6.9046 6.9046 0 0 1 5.217-2.382 6.9038 6.9038 0 0 1 5.217 2.382.5038.5038 0 0 1 .122.366.4962.4962 0 0 1-.174.348Z" />
        </g>
        <defs>
          <clipPath id="a"><path fill="#fff" d="M0 0h20v20H0z" /></clipPath>
        </defs>
      </svg>
    </div>
    <div class="menu-item w-full justify-center" (click)="changeRoute('catalog/categories')">
      <svg
        class="{{ currentRoute === 'catalog' ? 'fill-brand-lilac-400' : 'fill-brand-gray-400' }}"
        width="20"
        height="20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#a)">
          <path
            d="m18.9906 17.166-.912-9.342a1.9999 1.9999 0 0 0-2-1.82h-1.106v-.758A5.1224 5.1224 0 0 0 10.0006 0a5.122 5.122 0 0 0-4.972 5.246V6h-1.114a2 2 0 0 0-2 1.814l-.904 9.352A2.6002 2.6002 0 0 0 3.6006 20h12.8a2.5997 2.5997 0 0 0 2.4748-1.7698 2.6016 2.6016 0 0 0 .1252-1.0682l-.01.004ZM7.5286 5.246a2.624 2.624 0 0 1 2.472-2.75 2.6238 2.6238 0 0 1 2.472 2.75V6h-4.944v-.754Z" />
        </g>
        <defs>
          <clipPath id="a"><path fill="#fff" d="M0 0h20v20H0z" /></clipPath>
        </defs>
      </svg>
    </div>
    <div *ngIf="isAdminMode" class="menu-item w-full justify-center" (click)="changeRoute('statistics')">
      <svg
        class="{{ currentRoute.includes('statistics') ? 'fill-brand-lilac-400' : 'fill-brand-gray-400' }}"
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none">
        <g clip-path="url(#a)">
          <path
            d="M17 0H3a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3ZM5 3.8h5a1.25 1.25 0 0 1 0 2.5H5a1.25 1.25 0 0 1 0-2.5Zm12.08 5.83-3.5 6a1.248 1.248 0 0 1-1.848.356l-3.558-2.768-2.224 2.6a1.252 1.252 0 0 1-1.9-1.628l3-3.5a1.252 1.252 0 0 1 1.718-.172l3.372 2.618 2.78-4.766a1.25 1.25 0 0 1 2.16 1.26Z" />
        </g>
        <defs>
          <clipPath id="a"><path fill="#fff" d="M0 0h20v20H0z" /></clipPath>
        </defs>
      </svg>
    </div>
    <div *ngIf="isAdminMode" class="menu-item w-full justify-center" (click)="changeRoute('economy')">
      <svg
        class="{{ currentRoute.includes('economy') ? 'fill-brand-lilac-400' : 'fill-brand-gray-400' }}"
        width="20"
        height="20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#a)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1.151 2.1781C1.3818 1.1931 2.2494.4012 3.33.4012h13.3401c1.0814 0 1.9454.7923 2.1833 1.7697l.0098.0402c.2117.8693.4226 1.735.4226 4.2096 0 2.4745-.2109 3.3402-.4226 4.2095l-.0098.0402c-.2379.9774-1.1019 1.7697-2.1833 1.7697h-13.34c-1.0807 0-1.9483-.792-2.179-1.7769l-.016-.0683C.9254 9.7023.7143 8.8023.7143 6.4207c0-2.3817.2111-3.2816.4206-4.1742l.016-.0684Zm2.2287 12.0414c-.5918 0-1.0715.4796-1.0715 1.0713 0 .5919.4797 1.0715 1.0715 1.0715h13.2407c.5917 0 1.0714-.4796 1.0714-1.0715 0-.5917-.4797-1.0713-1.0714-1.0713H3.3797Zm.8144 4.6365c0-.5917.4797-1.0714 1.0714-1.0714h9.4693c.5917 0 1.0714.4797 1.0714 1.0714 0 .5917-.4797 1.0714-1.0714 1.0714H5.2655c-.5917 0-1.0714-.4797-1.0714-1.0714Zm5.8061-9.5973c1.5674 0 2.8379-1.2706 2.8379-2.838 0-1.5673-1.2705-2.8379-2.8379-2.8379-1.5673 0-2.838 1.2706-2.838 2.838 0 1.5673 1.2707 2.8379 2.838 2.8379Z" />
        </g>
        <defs>
          <clipPath id="a"><path fill="#fff" d="M0 0h20v20H0z" /></clipPath>
        </defs>
      </svg>
    </div>
    <div *ngIf="isAdminMode" class="menu-item w-full justify-center" (click)="changeRoute('settings')">
      <img
        class="w-5 h-5 {{ currentRoute.includes('settings') ? 'svg-fill-lilac-400' : 'svg-fill-gray-400' }}"
        src="assets/icon/settings.svg"
        alt="icon" />
    </div>
  </div>
</div>
