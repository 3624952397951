/*
 * Usage: number | localCurrency:'format'
 * If no format is provided 2 decimals will be used.
 // tslint:disable-next-line:jsdoc-format
*/

import { Pipe, PipeTransform } from '@angular/core';
import { formatCurrency, getCurrencySymbol } from '@angular/common';
import { LanguageService } from '@app/core/services/language/language.service';
import { Store } from '@ngrx/store';
import {IRootState} from "@app/core/store/root.state";
import {getCurrentState} from "@app/functions";

@Pipe({
	name: 'localCurrency',
})
export class LocalCurrencyPipe implements PipeTransform {
	constructor(private store: Store<IRootState>, private languageService: LanguageService) {}

	public transform(
		value: any,
		useSymbol: boolean = true,
		digitsFormat?: string,
		format?: 'wide' | 'narrow',
		currency?: string,
		valueOnly?: boolean
	): any {
		if (!currency) {
			currency = 'DKK'; // getCurrentState(this.store).authentication.defaultCurrency?.code;
		}

		if (!currency) {
			return value;
		}

		if (value === null) {
			return '';
		}
		if (!format) {
			format = 'narrow';
		}

		let symbol = getCurrencySymbol(currency, format, this.languageService.getCurrentLanguage().angularKey);

    if (value === 'symbol') {
			return symbol;
		}

		if (!digitsFormat) {
      if (Number.isInteger(value / 100)) {
        digitsFormat = '0.0-2';
      } else {
        digitsFormat = '0.2-2';
      }
		}

		if (!useSymbol) {
			symbol = currency;
		}

		valueOnly = !!valueOnly;

		return formatCurrency(
			value / 100,
			this.languageService.getCurrentLanguage().angularKey,
			valueOnly ? '' : symbol + ' ',
			currency,
			digitsFormat
		);
	}
}
