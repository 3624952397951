import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Store} from "@ngrx/store";
import {IRootState} from "@core/store/root.state";
import {catchError, mergeMap} from "rxjs/operators";
import {from, of} from "rxjs";
import {LoadError} from "@core/store/error/error.actions";
import {
  CreatePaymentMethod, CreatePaymentMethodSuccess, DeletePaymentMethod, DeletePaymentMethodSuccess, EditPaymentMethod, EditPaymentMethodSuccess,
  EEconomyActions,
  FetchPaymentMethods,
  FetchPaymentMethodsSuccess,
  FetchReport,
  FetchReportSuccess, FetchSaleById, FetchSaleByIdSuccess, FetchSales, FetchSalesSuccess
} from "@core/store/economy/economy.actions";
import {EconomyService} from "@core/store/economy/economy.service";
import {ActivatedRoute, Router} from "@angular/router";

@Injectable()
export class EconomyEffects {

  constructor(
    private actions$: Actions,
    private store: Store<IRootState>,
    private economyService: EconomyService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  public onFetchReport = createEffect(() => this.actions$.pipe(ofType<FetchReport>(EEconomyActions.FetchReport),
    mergeMap((action) => from(this.economyService.getReport(action.from_date, action.to_date)).pipe(
      mergeMap((data) => [new FetchReportSuccess(data)]),
      catchError((error) => of(new LoadError(error, action)))
    ))
  ));

  public onFetchSales = createEffect(() => this.actions$.pipe(ofType<FetchSales>(EEconomyActions.FetchSales),
    mergeMap((action) => from(this.economyService.getSales(action.from_date, action.to_date, action.next_page)).pipe(
      mergeMap((data) => [new FetchSalesSuccess(data)]),
      catchError((error) => of(new LoadError(error, action)))
    ))
  ));

  public onFetchSaleById = createEffect(() => this.actions$.pipe(ofType<FetchSaleById>(EEconomyActions.FetchSaleById),
    mergeMap((action) => from(this.economyService.getSaleById(action.id)).pipe(
      mergeMap((data) => [new FetchSaleByIdSuccess(data)]),
      catchError((error) => of(new LoadError(error, action)))
    ))
  ));

  public onFetchPaymentMethods = createEffect(() => this.actions$.pipe(ofType<FetchPaymentMethods>(EEconomyActions.FetchPaymentMethods),
    mergeMap((action) => from(this.economyService.getPaymentMethods()).pipe(
      mergeMap((data) => [new FetchPaymentMethodsSuccess(data)]),
      catchError((error) => of(new LoadError(error, action)))
    ))
  ));

  public onCreatePaymentMethod = createEffect(() => this.actions$.pipe(ofType<CreatePaymentMethod>(EEconomyActions.CreatePaymentMethod),
    mergeMap((action) => from(this.economyService.createPaymentMethod(action.name, action.paymentType)).pipe(
      mergeMap(() => {
        this.router.navigate(['portal/settings/payment-methods'], {relativeTo: this.route});

        return [new CreatePaymentMethodSuccess(), new FetchPaymentMethods()];
      }),
      catchError((error) => of(new LoadError(error, action)))
    ))
  ));

  public onEditPaymentMethod = createEffect(() => this.actions$.pipe(ofType<EditPaymentMethod>(EEconomyActions.EditPaymentMethod),
    mergeMap((action) => from(this.economyService.editPaymentMethod(action.id, action.name, action.paymentType)).pipe(
      mergeMap(() => {
        this.router.navigate(['portal/settings/payment-methods'], {relativeTo: this.route});

        return [new EditPaymentMethodSuccess(), new FetchPaymentMethods()];
      }),
      catchError((error) => of(new LoadError(error, action)))
    ))
  ));

  public onDeletePaymentMethods = createEffect(() => this.actions$.pipe(ofType<DeletePaymentMethod>(EEconomyActions.DeletePaymentMethod),
    mergeMap((action) => from(this.economyService.deletePaymentMethod(action.id)).pipe(
      mergeMap(() => {
        this.router.navigate(['portal/settings/payment-methods'], {relativeTo: this.route});

        return [new DeletePaymentMethodSuccess(), new FetchPaymentMethods()];
      }),
      catchError((error) => of(new LoadError(error, action)))
    ))
  ));

}
