import {EventEmitter, Injectable} from "@angular/core";
import {Subscription, takeUntil} from "rxjs";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {ENotification} from "@enums/notification.enum";

@UntilDestroy()
@Injectable({
	providedIn: 'root'
})
export class NotificationService {
	private notificationEvent = new EventEmitter<any>();

	public sendNotification(type: ENotification, notification: any): void {
		this.notificationEvent.emit({type, notification});
	}

	public receiveNotification(callback: (notification: { type: ENotification, data: any }) => void): Subscription {
		return this.notificationEvent.pipe(untilDestroyed(this)).subscribe(callback);
	}
}
