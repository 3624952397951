import {EErrorActions, ErrorActions} from "@core/store/error/error.actions";
import produce from "immer";
import {ICatalogState, initialCatalogState} from "@core/store/catalog/catalog.state";
import {CatalogActions, ECatalogActions} from "@core/store/catalog/catalog.actions";

export const catalogReducers = (state: ICatalogState = initialCatalogState, action: CatalogActions | ErrorActions): ICatalogState => {
	let nextState;

	switch (action.type) {
		// Fetch Categories

		case ECatalogActions.FetchCategories:
			nextState = produce(state, (draftState) => {
				draftState.isLoading = true;
			});

			return nextState;
		case ECatalogActions.FetchCategoriesSuccess:
			nextState = produce(state, (draftState) => {
				draftState.isLoading = false;
				draftState.categories = action.categories;
			});

			return nextState;

		// Create Category

		case ECatalogActions.CreateCategory:
			nextState = produce(state, (draftState) => {
				draftState.isLoading = true;
			});

			return nextState;
		case ECatalogActions.CreateCategorySuccess:
			nextState = produce(state, (draftState) => {
				draftState.isLoading = false;
			});

			return nextState;

		// Update Category

		case ECatalogActions.UpdateCategoryWithImage:
		case ECatalogActions.UpdateCategory:
			nextState = produce(state, (draftState) => {
				draftState.isLoading = true;
			});

			return nextState;
		case ECatalogActions.UpdateCategoryWithImageSuccess:
		case ECatalogActions.UpdateCategorySuccess:
			nextState = produce(state, (draftState) => {
				draftState.isLoading = false;
			});

			return nextState;

		// Delete Category

		case ECatalogActions.DeleteCategory:
			nextState = produce(state, (draftState) => {
				draftState.isLoading = true;
			});

			return nextState;
		case ECatalogActions.DeleteCategorySuccess:
			nextState = produce(state, (draftState) => {
				draftState.isLoading = false;
			});

			return nextState;

		// Fetch Products

		case ECatalogActions.FetchProducts:
			nextState = produce(state, (draftState) => {
				draftState.isLoading = true;
			});

			return nextState;
		case ECatalogActions.FetchProductsSuccess:
			nextState = produce(state, (draftState) => {
				draftState.isLoading = false;
				draftState.products = action.products;
			});

			return nextState;

    // Fetch Category Products

    case ECatalogActions.FetchCategoryProducts:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = true;
        draftState.selectedCategoryId = action.category_id;
      });

      return nextState;
    case ECatalogActions.FetchCategoryProductsSuccess:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = false;
        draftState.categoryProducts = action.products;
      });

      return nextState;

		// Create Product

		case ECatalogActions.CreateProduct:
			nextState = produce(state, (draftState) => {
				draftState.isLoading = true;
			});

			return nextState;
		case ECatalogActions.CreateProductSuccess:
			nextState = produce(state, (draftState) => {
				draftState.isLoading = false;
			});

			return nextState;

		// Update Product

		case ECatalogActions.UpdateProduct:
			nextState = produce(state, (draftState) => {
				draftState.isLoading = true;
			});

			return nextState;
		case ECatalogActions.UpdateProductSuccess:
			nextState = produce(state, (draftState) => {
				draftState.isLoading = false;
			});

			return nextState;

    // Set Selected Category ID

    case ECatalogActions.SetSelectedCategoryId:
      nextState = produce(state, (draftState) => {
        draftState.selectedCategoryId = action.id;
      });

      return nextState;

		// ERROR

		case EErrorActions.LoadError:
			nextState = produce(state, (draftState) => {
				draftState.isLoading = false;
			});

			return nextState;
		default:
			return state;
	}
};
