import {PaginationHolder} from "@models/pagination.model";
import {ICustomerModel} from "@models/customer.model";
import {IJournalEntryModel} from "@models/journal-entry.model";
import {ICustomerSMSHistoryModel} from "@models/customer-sms-history.model";

export interface ICustomerState {
  isLoading: boolean;
  customers?: PaginationHolder<ICustomerModel>;
  currentCustomer?: ICustomerModel;
  createdCustomer?: ICustomerModel;
  customerJournal?: PaginationHolder<IJournalEntryModel>;
  customerSMSHistory?: PaginationHolder<ICustomerSMSHistoryModel>;
}

export const initialCustomerState: ICustomerState = {
  isLoading: false,
}
