import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {AvatarNameInitialsPipe} from "@app/shared/pipes/avatar-name-initials/avatar-name-initials.pipe";

@NgModule({
	declarations: [AvatarNameInitialsPipe],
	imports: [CommonModule],
	exports: [AvatarNameInitialsPipe],
})
export class AvatarNameInitialsModule {}
