import { Pipe, PipeTransform } from '@angular/core';
import {IUserModel} from "@models/user.model";

@Pipe({
  name: 'avatarNameInitials'
})
export class AvatarNameInitialsPipe implements PipeTransform {

  public transform(value?: IUserModel, customer_name?: string): string {
    const name = value?.name ?? customer_name;

    if (!name) {
      return  '--';
    }

		const split = name.split(' ');

		if (split.length > 1) {
			return (split[0]?.charAt(0) ?? '-') + (split[1]?.charAt(0) ?? '-');
		}

		return (name.charAt(0) ?? '-') + (name.charAt(1) ?? '-');
  }

}
