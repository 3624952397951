import {Directive, ElementRef, HostListener} from "@angular/core";

@Directive({
  selector: 'input[type="number"][disableNumberScroll]',
  standalone: true,
})
export class DisableNumberInputScrollDirective {
  constructor(private elementRef: ElementRef) {}

  @HostListener('mousewheel', ['$event'])
  public onMouseWheel(event: Event): void {
    event.preventDefault();
  }
}
