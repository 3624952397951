<div class="flex flex-col gap-6 min-w-[520px]">
  <h1 class="font-bold">Refunder betaling</h1>

  <!-- Sale data -->
  <div class="flex flex-col gap-4">
    <!-- Sale Lines -->
    <div class="flex flex-col gap-2">
      <!-- Line -->
      <div *ngFor="let line of sale?.lines" (click)="didClickLine(line)" class="flex justify-between p-2 bg-brand-gray-50 gap-4 rounded-lg cursor-pointer {{selectedLines.includes(line) ? 'bg-brand-lilac-600 text-white' : 'hover:bg-brand-lilac-200'}}">
        <div class="flex gap-2 pointer-events-none">
          <input [checked]="selectedLines.includes(line)" type="checkbox" class="w-6 h-6 text-brand-dark bg-brand-gray-200 border-brand-gray-200 rounded focus:outline-none ring-0">
          <p class="font-medium">{{line.description}}</p>
        </div>

        <p class="font-bold">{{line.total_amount | localCurrency}}</p>
      </div>
    </div>

    <!-- Total -->
    <div class="flex flex-col w-full">
      <div class="flex gap-4 justify-between items-center" *ngIf="totalRefunded > 0">
        <p>Refunderet</p>
        <p class="font-bold">-{{totalRefunded | localCurrency}}</p>
      </div>
      <div class="flex gap-4 justify-between items-center">
        <p>Total</p>
        <h1 class="font-bold">{{sale?.total_amount | localCurrency}}</h1>
      </div>
    </div>
  </div>

  <!-- Refund field -->
  <div class="flex flex-col gap-2">
    <p class="font-medium text-brand-gray-600 text-sm">Refundering</p>

    <form *ngIf="validationForm" [formGroup]="validationForm">
      <div class="flex relative">
        <input [value]="refund" disableNumberScroll type="number" formControlName="input" placeholder="Indtast beløb" class="box-input">
        <div class="flex items-center justify-center px-2 py-1 absolute right-2 top-1/2 -translate-y-1/2 bg-brand-gray-200 rounded-full">
          <p class="text-sm font-medium">DKK</p>
        </div>
      </div>
      <div *ngIf="validationForm.get('input')?.invalid && (validationForm.get('input')?.dirty || validationForm.get('input')?.touched)">
        <p class="text-brand-error-500 font-medium" *ngIf="validationForm.get('input')?.hasError('required')">Beløb er påkrævet</p>
        <p class="text-brand-error-500 font-medium" *ngIf="validationForm.get('input')?.hasError('max')">Beløbet er større end det resterende beløb</p>
        <p class="text-brand-error-500 font-medium" *ngIf="validationForm.get('input')?.hasError('min')">Beløbet må ikke være mindre end 1 kr.</p>
      </div>
    </form>

  </div>

  <button class="secondary-button medium-btn mt-4" (click)="didClickRefund()">
    Refunder
  </button>
</div>
