import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "@env/environment";
import {IOrderModel} from "@models/order.model";
import {ICreateOrderRequestModel} from "@models/request/create-order.request.model";

@Injectable({
    providedIn: 'root',
})
export class OrderService {
  constructor(
    private http: HttpClient,
  ) {}

  public getOrderById(id: string): Observable<IOrderModel> {
    return this.http.get<IOrderModel>(`${environment.api_url}/orders/${id}`);
  }

  public addToOrder(id: string, line: any): Observable<any> {
    return this.http.post<IOrderModel>(`${environment.api_url}/orders/${id}/lines`, line);
  }

  public removeFromOrder(orderId: string, lineId: string): Observable<any> {
    return this.http.delete<IOrderModel>(`${environment.api_url}/orders/${orderId}/lines/${lineId}`);
  }

  public patchOrderLine(order_id: string, line_id: string, line: any): Observable<any> {
    return this.http.patch<IOrderModel>(`${environment.api_url}/orders/${order_id}/lines/${line_id}`, line);
  }

  public createOrder(reqData: ICreateOrderRequestModel): Observable<any> {
    return this.http.post<IOrderModel>(`${environment.api_url}/orders`, reqData);
  }
}
