import {EffectsModule} from "@ngrx/effects";
import {CommonModule} from "@angular/common";
import {StoreModule} from "@ngrx/store";
import {CustomerEffects} from "@core/store/customer/customer.effects";
import {NgModule} from "@angular/core";
import {customerReducers} from "@core/store/customer/customer.reducers";

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('customer', customerReducers),
        EffectsModule.forFeature([CustomerEffects]),
    ],
    providers: [CustomerEffects],
})
export class CustomerStoreModule {
}
