import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { IRootState } from '@core/store/root.state';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { LoadError } from '@core/store/error/error.actions';
import {
  CreateCategory,
  CreateCategorySuccess,
  CreateProduct,
  CreateProductSuccess,
  DeleteCategory,
  DeleteCategorySuccess,
  DeleteProduct,
  DeleteProductSuccess,
  ECatalogActions,
  FetchCategories,
  FetchCategoriesSuccess,
  FetchCategoryProducts,
  FetchCategoryProductsSuccess,
  FetchProducts,
  FetchProductsSuccess,
  SortCategories,
  SortCategoriesSuccess,
  UpdateCategory,
  UpdateCategorySuccess,
  UpdateCategoryWithImage,
  UpdateProduct,
  UpdateProductSuccess,
} from '@core/store/catalog/catalog.actions';
import { CatalogService } from '@core/store/catalog/catalog.service';
import { NotificationService } from '@services/notification/notification.service';
import { ENotification } from '@enums/notification.enum';
import { UploadService } from '@core/store/upload/upload.service';
import { selectCategories, selectSelectedCategoryId } from '@core/store/catalog/catalog.selectors';
import { LogContentService } from '@app/shared/components/log-content/services/log-content.service';
import { ELogLevel } from '@app/shared/components/log-content/enums/log-level.enum';

@Injectable()
export class CatalogEffects {
  constructor(
    private actions$: Actions,
    private store: Store<IRootState>,
    private catalogService: CatalogService,
    private notificationService: NotificationService,
    private uploadService: UploadService,
    private toastService: LogContentService
  ) {}

  public onFetchCategories = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchCategories>(ECatalogActions.FetchCategories),
      mergeMap(action =>
        from(this.catalogService.getCategories()).pipe(
          mergeMap(categories => [new FetchCategoriesSuccess(categories)]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onCreateCategory = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateCategory>(ECatalogActions.CreateCategory),
      withLatestFrom(this.store.select(selectCategories)),
      mergeMap(([action, categoriesPagination]) =>
        from(this.catalogService.createCategory(action.reqModel, categoriesPagination)).pipe(
          mergeMap(category => {
            if (!action.file) {
              this.notificationService.sendNotification(ENotification.CATEGORY_UPDATED, null);

              return [new CreateCategorySuccess(), new FetchCategories()];
            }

            return this.uploadService.uploadImageWithSignedURL(action.file).pipe(
              mergeMap(image =>
                this.catalogService.updateCategory(category.id, { ...action.reqModel, image_id: image.id }).pipe(
                  mergeMap(_ => {
                    this.notificationService.sendNotification(ENotification.CATEGORY_UPDATED, null);

                    return [new CreateCategorySuccess(), new FetchCategories()];
                  }),
                  catchError(error => of(new LoadError(error, action)))
                )
              ),
              catchError(error => of(new LoadError(error, action)))
            );
          }),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onUpdateCategoryWithImage = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateCategoryWithImage>(ECatalogActions.UpdateCategoryWithImage),
      mergeMap(action =>
        this.uploadService.uploadImageWithSignedURL(action.file).pipe(
          mergeMap(image =>
            this.catalogService.updateCategory(action.id, { ...action.reqModel, image_id: image.id }).pipe(
              mergeMap(category => {
                this.notificationService.sendNotification(ENotification.CATEGORY_UPDATED, null);

                return [new UpdateCategorySuccess(), new FetchCategories()];
              }),
              catchError(error => of(new LoadError(error, action)))
            )
          ),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onUpdateCategory = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateCategory>(ECatalogActions.UpdateCategory),
      mergeMap(action =>
        from(this.catalogService.updateCategory(action.id, action.reqModel)).pipe(
          mergeMap(category => {
            this.notificationService.sendNotification(ENotification.CATEGORY_UPDATED, null);

            return [new UpdateCategorySuccess(), new FetchCategories(), new FetchCategoryProducts(action.id)];
          }),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onDeleteCategory = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteCategory>(ECatalogActions.DeleteCategory),
      mergeMap(action =>
        from(this.catalogService.deleteCategory(action.id)).pipe(
          mergeMap(category => {
            this.notificationService.sendNotification(ENotification.CATEGORY_UPDATED, null);

            return [new DeleteCategorySuccess(), new FetchCategories()];
          }),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onSortCategories = createEffect(() =>
    this.actions$.pipe(
      ofType<SortCategories>(ECatalogActions.SortCategories),
      mergeMap(action =>
        from(this.catalogService.sortCategories(action.categories)).pipe(
          mergeMap(() => {
            this.toastService.logContent({
              level: ELogLevel.SUCCESS,
              description: 'Kategori rækkefølge opdateret',
            });

            return [new SortCategoriesSuccess(), new FetchCategories()];
          }),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onFetchProducts = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchProducts>(ECatalogActions.FetchProducts),
      mergeMap(action =>
        from(this.catalogService.getProducts(action.fetch_type)).pipe(
          mergeMap(products => [new FetchProductsSuccess(products)]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onFetchCategoryProducts = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchCategoryProducts>(ECatalogActions.FetchCategoryProducts),
      mergeMap(action =>
        from(this.catalogService.getCategoryProducts(action.category_id)).pipe(
          mergeMap(products => [new FetchCategoryProductsSuccess(products)]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onCreateProduct = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateProduct>(ECatalogActions.CreateProduct),
      mergeMap(action =>
        from(this.catalogService.createProduct(action.reqModel)).pipe(
          mergeMap(product => {
            const actions: Action[] = [new CreateProductSuccess()];

            action.categories.forEach(cat => {
              const products: { id: string }[] = [...cat.products.map(prod => ({ id: prod.id })), { id: product.id }];
              actions.push(new UpdateCategory(cat.id, { name: cat.name, products }));
            });

            this.notificationService.sendNotification(ENotification.PRODUCT_UPDATED, null);

            return [...actions, new FetchCategories(), new FetchProducts()];
          }),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onUpdateProduct = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateProduct>(ECatalogActions.UpdateProduct),
      withLatestFrom(this.store.select(selectSelectedCategoryId)),
      mergeMap(([action, category_id]) =>
        from(this.catalogService.updateProduct(action.id, action.reqModel)).pipe(
          mergeMap(product => {
            const actions: Action[] = [new UpdateProductSuccess()];

            action.previousCategories.forEach(cat => {
              const products: { id: string }[] = [...cat.products.filter(prod => prod.id !== action.id).map(prod => ({ id: prod.id }))];
              actions.push(new UpdateCategory(cat.id, { name: cat.name, products }));
            });

            action.newCategories.forEach(cat => {
              const products: { id: string }[] = [...cat.products.map(prod => ({ id: prod.id })), { id: product.id }];
              actions.push(new UpdateCategory(cat.id, { name: cat.name, products }));
            });

            this.notificationService.sendNotification(ENotification.PRODUCT_UPDATED, null);

            if (category_id) {
              actions.push(new FetchCategoryProducts(category_id));
            }

            return [...actions, new FetchCategories(), new FetchProducts()];
          }),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onDeleteProduct = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteProduct>(ECatalogActions.DeleteProduct),
      mergeMap(action =>
        from(this.catalogService.deleteProduct(action.id)).pipe(
          mergeMap(() => [new DeleteProductSuccess(), new FetchProducts()]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );
}
