import { Action } from '@ngrx/store';
import { IRootState } from '@app/core/store/root.state';

export enum EHydrateActions {
	Hydrate = '[Hydration] Hydrate',
	HydrateSuccess = '[Hydration] Hydrate Success',
	HydrateFailure = '[Hydration] Hydrate Failure',
  ClearHydration = '[Hydration] Clear Hydration',
}

export class Hydrate implements Action {
	public readonly type = EHydrateActions.Hydrate;

	constructor() {}
}

export class HydrateSuccess implements Action {
	public readonly type = EHydrateActions.HydrateSuccess;

	constructor(public state: IRootState) {}
}

export class HydrateFailure implements Action {
	public readonly type = EHydrateActions.HydrateFailure;

	constructor() {}
}

export class ClearHydration implements Action {
  public readonly type = EHydrateActions.ClearHydration;

  constructor() {}
}

export type HydrateActions = Hydrate | HydrateSuccess | HydrateFailure | ClearHydration;
