import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Action, Store} from "@ngrx/store";
import {IRootState} from "@core/store/root.state";
import {Router} from "@angular/router";
import {catchError, mergeMap, withLatestFrom} from "rxjs/operators";
import {from, of} from "rxjs";
import {BookingService} from "@core/store/booking/booking.service";
import {
  CheckOverlap,
  CheckOverlapSuccess,
  CreateBlocking,
  CreateBlockingSuccess,
  CreateBooking,
  CreateBookingSuccess,
  DeleteBlocking,
  DeleteBlockingSuccess,
  DeleteBooking,
  DeleteBookingSuccess,
  EBookingActions,
  FetchBookingById,
  FetchBookingByIdSuccess,
  FetchBookingSettings,
  FetchBookingSettingsSuccess,
  FetchCalendarBookings,
  FetchCalendarBookingsSuccess,
  FetchCustomerBookings,
  FetchCustomerBookingsSuccess,
  FetchWaitlist, FetchWaitlistSuccess,
  SetIsWeekView,
  UpdateBlocking,
  UpdateBlockingSuccess,
  UpdateBooking,
  UpdateBookingSettings,
  UpdateBookingSettingsSuccess
} from "@core/store/booking/booking.actions";
import {LoadError} from "@core/store/error/error.actions";
import {
  selectCurrentCalendarUser,
  selectCurrentDateRange, selectIsWeekView
} from "@core/store/booking/booking.selectors";
import {LogContentService} from "@app/shared/components/log-content/services/log-content.service";
import {ELogLevel} from "@app/shared/components/log-content/enums/log-level.enum";
import {FetchCustomerById} from "@core/store/customer/customer.actions";
import {NotificationService} from "@services/notification/notification.service";
import {ENotification} from "@enums/notification.enum";

@Injectable()
export class BookingEffects {
	constructor(
		private actions$: Actions,
		private store: Store<IRootState>,
		private bookingService: BookingService,
		private router: Router,
    private logService: LogContentService,
    private notificationService: NotificationService,
	) {}

  public onFetchCalendarBookings = createEffect(() => this.actions$.pipe(ofType<FetchCalendarBookings>(EBookingActions.FetchCalendarBookings),
    withLatestFrom(this.store.select(selectCurrentDateRange), this.store.select(selectIsWeekView), this.store.select(selectCurrentCalendarUser)),
    mergeMap(([action, dateRange, isWeekView, calendarUser]) => from(this.bookingService.fetchCalendarBookings(dateRange.start, dateRange.end, isWeekView, calendarUser)).pipe(
      mergeMap((bookings) => [new FetchCalendarBookingsSuccess(bookings)]),
      catchError((error) => of(new LoadError(error, action)))
    ))
  ));

  public onFetchBookingById = createEffect(() => this.actions$.pipe(ofType<FetchBookingById>(EBookingActions.FetchBookingById),
    mergeMap((action) => from(this.bookingService.fetchBooking(action.id)).pipe(
      mergeMap((data) => {
        const actions: Action[] = [new FetchBookingByIdSuccess(data)];

        if (data.customer_id) {
          actions.push(new FetchCustomerById(data.customer_id));
        }

        return actions;
      }),
      catchError((error) => of(new LoadError(error, action)))
    ))
  ));

  public onFetchBookingSettings = createEffect(() => this.actions$.pipe(ofType<FetchBookingSettings>(EBookingActions.FetchBookingSettings),
    mergeMap((action) => from(this.bookingService.fetchBookingSettings()).pipe(
      mergeMap((settings) => [new FetchBookingSettingsSuccess(settings)]),
      catchError((error) => of(new LoadError(error, action)))
    ))
  ));

  public onUpdateBookingSettings = createEffect(() => this.actions$.pipe(ofType<UpdateBookingSettings>(EBookingActions.UpdateBookingSettings),
    mergeMap((action) => from(this.bookingService.updateBookingSettings(action.reqModel)).pipe(
      mergeMap((settings) => [new UpdateBookingSettingsSuccess(settings)]),
      catchError((error) => of(new LoadError(error, action)))
    ))
  ));

	public onCreateBooking = createEffect(() => this.actions$.pipe(ofType<CreateBooking>(EBookingActions.CreateBooking),
		mergeMap((action) => from(this.bookingService.createBooking(action.reqModel)).pipe(
			mergeMap((user) => {
        this.logService.logContent({
          level: ELogLevel.SUCCESS,
          description: 'Booking oprettet',
          actions: [],
        });

        this.notificationService.sendNotification(ENotification.BOOKING_UPDATED, null);

        return [new CreateBookingSuccess(user), new FetchCalendarBookings()];
      }),
			catchError((error) => of(new LoadError(error, action)))
		))
	));

	public onUpdateBooking = createEffect(() => this.actions$.pipe(ofType<UpdateBooking>(EBookingActions.UpdateBooking),
		mergeMap((action) => from(this.bookingService.updateBooking(action.booking_id, action.reqModel)).pipe(
			mergeMap((user) => {
        this.logService.logContent({
          level: ELogLevel.SUCCESS,
          description: 'Booking opdateret',
          actions: [],
        });

        this.notificationService.sendNotification(ENotification.BOOKING_UPDATED, null);

        return [new CreateBookingSuccess(user), new FetchBookingById(action.booking_id), new FetchCalendarBookings()];
      }),
			catchError((error) => of(new LoadError(error, action)))
		))
	));

  public onCheckOverlap = createEffect(() => this.actions$.pipe(ofType<CheckOverlap>(EBookingActions.CheckOverlap),
    mergeMap((action) => from(this.bookingService.checkForOverlap(action.from_date, action.to_date, action.user_id, action.booking_id, action.time_block_id)).pipe(
      mergeMap((bookings) => [new CheckOverlapSuccess(bookings)]),
      catchError((error) => of(new LoadError(error, action)))
    ))
  ));

  public onFetchCustomerBookings = createEffect(() => this.actions$.pipe(ofType<FetchCustomerBookings>(EBookingActions.FetchCustomerBookings),
    mergeMap((action) => from(this.bookingService.fetchCustomerBookings(action.id)).pipe(
      mergeMap((bookings) => [new FetchCustomerBookingsSuccess(bookings)]),
      catchError((error) => of(new LoadError(error, action)))
    ))
  ));

  public onDeleteBooking = createEffect(() => this.actions$.pipe(ofType<DeleteBooking>(EBookingActions.DeleteBooking),
    mergeMap((action) => from(this.bookingService.deleteBooking(action.booking.id)).pipe(
      mergeMap(() => {
        this.notificationService.sendNotification(ENotification.BOOKING_DELETED, null);

        return [new DeleteBookingSuccess(), new FetchCalendarBookings()];
      }),
      catchError((error) => of(new LoadError(error, action)))
    ))
  ));

  public onSetIsWeekView = createEffect(() => this.actions$.pipe(ofType<SetIsWeekView>(EBookingActions.SetIsWeekView),
    mergeMap((action) => [new FetchCalendarBookings()])
  ));

  public onCreateBlocking = createEffect(() => this.actions$.pipe(ofType<CreateBlocking>(EBookingActions.CreateBlocking),
    mergeMap((action) => from(this.bookingService.createBlocking(action.reqData)).pipe(
      mergeMap(() => {
        this.notificationService.sendNotification(ENotification.BLOCKING_UPDATED, null);

        return [new CreateBlockingSuccess(), new FetchCalendarBookings()];
      }),
      catchError((error) => of(new LoadError(error, action)))
    ))
  ));

  public onUpdateBlocking = createEffect(() => this.actions$.pipe(ofType<UpdateBlocking>(EBookingActions.UpdateBlocking),
    mergeMap((action) => from(this.bookingService.updateBlocking(action.id, action.reqData)).pipe(
      mergeMap(() => {
        this.notificationService.sendNotification(ENotification.BLOCKING_UPDATED, null);

        return [new UpdateBlockingSuccess(), new FetchCalendarBookings()];
      }),
      catchError((error) => of(new LoadError(error, action)))
    ))
  ));

  public onDeleteBlocking = createEffect(() => this.actions$.pipe(ofType<DeleteBlocking>(EBookingActions.DeleteBlocking),
    mergeMap((action) => from(this.bookingService.deleteBlocking(action.id)).pipe(
      mergeMap(() => {
        this.notificationService.sendNotification(ENotification.BLOCKING_UPDATED, null);

        return [new DeleteBlockingSuccess(), new FetchCalendarBookings()];
      }),
      catchError((error) => of(new LoadError(error, action)))
    ))
  ));

  public onFetchWaitlist = createEffect(() => this.actions$.pipe(ofType<FetchWaitlist>(EBookingActions.FetchWaitlist),
    mergeMap((action) => from(this.bookingService.fetchWaitlist()).pipe(
      mergeMap((data) => [new FetchWaitlistSuccess(data)]),
      catchError((error) => of(new LoadError(error, action)))
    ))
  ));
}
