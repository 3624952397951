import {EffectsModule} from "@ngrx/effects";
import {CommonModule} from "@angular/common";
import {StoreModule} from "@ngrx/store";
import {SettingsEffects} from "@core/store/settings/settings.effects";
import {NgModule} from "@angular/core";
import {settingsReducers} from "@core/store/settings/settings.reducers";

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('settings', settingsReducers),
        EffectsModule.forFeature([SettingsEffects]),
    ],
    providers: [SettingsEffects],
})
export class SettingsStoreModule {
}
