import { PaginationHolder } from '@models/pagination.model';
import { IBookingHourModel } from '@models/booking-hour.model';
import { ISMSSettingsModel } from '@models/sms-settings.model';
import { ISMSTemplateModel } from '@models/sms-template.model';
import { IBillingSettingsModel } from '@models/billing-settings.model';

export interface ISettingsState {
  isLoading: boolean;
  isSidebarVisible: boolean;
  booking_hours?: PaginationHolder<IBookingHourModel>;
  smsSettings?: ISMSSettingsModel;
  smsTemplates?: PaginationHolder<ISMSTemplateModel>;
}

export const initialSettingsState: ISettingsState = {
  isLoading: false,
  isSidebarVisible: true,
};
