import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, mergeMap} from "rxjs/operators";
import {from, of} from "rxjs";
import {LoadError} from "@core/store/error/error.actions";
import {VoucherService} from "@core/store/voucher/voucher.service";
import {
  CreateVoucher,
  CreateVoucherSuccess,
  EVoucherActions,
  FetchVoucherById,
  FetchVoucherByIdSuccess,
  FetchVoucherIntents,
  FetchVoucherIntentsSuccess,
  FetchVouchers,
  FetchVouchersSuccess,
  SendVoucher,
  SendVoucherSuccess,
  UpdateVoucher,
  UpdateVoucherSuccess
} from "@core/store/voucher/voucher.actions";
import {ICreateOrderRequestModel} from "@models/request/create-order.request.model";
import {CreateOrder} from "@core/store/order/order.actions";
import {LogContentService} from "@app/shared/components/log-content/services/log-content.service";
import {ELogLevel} from "@app/shared/components/log-content/enums/log-level.enum";
import {NotificationService} from "@services/notification/notification.service";
import {ENotification} from "@enums/notification.enum";

@Injectable()
export class VoucherEffects {
  constructor(
    private actions$: Actions,
    private voucherService: VoucherService,
    private logService: LogContentService,
    private notificationService: NotificationService,
  ) {}

  public onFetchVouchers = createEffect(() => this.actions$.pipe(ofType<FetchVouchers>(EVoucherActions.FetchVouchers),
    mergeMap((action) => from(this.voucherService.getVouchers(action.search, action.next_page)).pipe(
      mergeMap((data) => [new FetchVouchersSuccess(data)]),
      catchError((error) => of(new LoadError(error, action)))
    ))
  ));

  public onFetchVoucherById = createEffect(() => this.actions$.pipe(ofType<FetchVoucherById>(EVoucherActions.FetchVoucherById),
    mergeMap((action) => from(this.voucherService.getVoucher(action.id)).pipe(
      mergeMap((data) => [new FetchVoucherByIdSuccess(data)]),
      catchError((error) => of(new LoadError(error, action)))
    ))
  ));

  public onFetchVoucherIntents = createEffect(() => this.actions$.pipe(ofType<FetchVoucherIntents>(EVoucherActions.FetchVoucherIntents),
    mergeMap((action) => from(this.voucherService.getVoucherIntents(action.id)).pipe(
      mergeMap((data) => [new FetchVoucherIntentsSuccess(data)]),
      catchError((error) => of(new LoadError(error, action)))
    ))
  ));

  public onCreateVoucher = createEffect(() => this.actions$.pipe(ofType<CreateVoucher>(EVoucherActions.CreateVoucher),
    mergeMap((action) => from(this.voucherService.createVoucher(action.reqData)).pipe(
      mergeMap((data) => {
        const order: ICreateOrderRequestModel = {
          lines: [
            {
              description: 'Gavekort',
              discount_amount: 0,
              quantity: 1,
              total_amount: action.reqData.amount,
              gift_card_id: data.id,
            }
          ]
        }

        return [new CreateVoucherSuccess(data), new CreateOrder(order)]
      }),
      catchError((error) => of(new LoadError(error, action)))
    ))
  ));

  public onUpdateVoucher = createEffect(() => this.actions$.pipe(ofType<UpdateVoucher>(EVoucherActions.UpdateVoucher),
    mergeMap((action) => from(this.voucherService.updateVoucher(action.id, action.reqData)).pipe(
      mergeMap((data) => {
        this.notificationService.sendNotification(ENotification.VOUCHER_UPDATED, undefined);

        return [new UpdateVoucherSuccess(data)];
      }),
      catchError((error) => of(new LoadError(error, action)))
    ))
  ));

  public onSendVoucher = createEffect(() => this.actions$.pipe(ofType<SendVoucher>(EVoucherActions.SendVoucher),
    mergeMap((action) => from(this.voucherService.sendVoucher(action.id, action.email)).pipe(
      mergeMap(() => {
        this.logService.logContent({
          level: ELogLevel.SUCCESS,
          description: 'Gavekort er blevet sendt til: ' + action.email,
        });

        return [new SendVoucherSuccess()];
      }),
      catchError((error) => of(new LoadError(error, action)))
    ))
  ));
}
