import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {LocalDatePipe} from "@app/shared/pipes/local-date/local-date.pipe";

@NgModule({
  declarations: [LocalDatePipe],
  imports: [CommonModule],
  exports: [LocalDatePipe],
})
export class LocalDateModule {}
