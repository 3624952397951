import {EffectsModule} from "@ngrx/effects";
import {CommonModule} from "@angular/common";
import {StoreModule} from "@ngrx/store";
import {BillingEffects} from "@core/store/billing/billing.effects";
import {NgModule} from "@angular/core";
import {billingReducers} from "@core/store/billing/billing.reducers";

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('billing', billingReducers),
        EffectsModule.forFeature([BillingEffects]),
    ],
    providers: [BillingEffects],
})
export class BillingStoreModule {
}
