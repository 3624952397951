import produce from "immer";
import {EErrorActions, ErrorActions} from "@app/core/store/error/error.actions";
import {initialUploadState, IUploadState} from "@app/core/store/upload/upload.state";
import {EUploadActions, UploadActions} from "@app/core/store/upload/upload.actions";

export const uploadReducers = (state: IUploadState = initialUploadState, action: UploadActions | ErrorActions): IUploadState => {
	let nextState;

	switch (action.type) {
		case EUploadActions.UploadAccountImage:
		case EUploadActions.UploadCategoryImage:
    case EUploadActions.UploadUserImage:
			nextState = produce(state, (draftState) => {
				draftState.isLoading = true;
			});

			return nextState;

		case EUploadActions.UploadAccountImageSuccess:
    case EUploadActions.UploadCategoryImageSuccess:
    case EUploadActions.UploadUserImageSuccess:
			nextState = produce(state, (draftState) => {
				draftState.isLoading = false;
			});

			return nextState;


		// ERROR

		case EErrorActions.LoadError:
			nextState = produce(state, (draftState) => {
				draftState.isLoading = false;
			});

			return nextState;
		default:
			return state;
	}
};

