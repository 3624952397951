import {IPaymentIntentModel} from "@models/payment-intent.model";
import {PaginationHolder} from "@models/pagination.model";

export interface IPaymentState {
  isLoading: boolean;
  paymentIntents?: PaginationHolder<IPaymentIntentModel>;
}

export const initialPaymentState: IPaymentState = {
  isLoading: false,
}
