import {IRootState} from "@core/store/root.state";
import {createSelector} from "@ngrx/store";
import {IBookingState} from "@core/store/booking/booking.state";
import * as moment from 'moment';

const _selectBooking = (state: IRootState) => state.booking;

export const selectBookingIsLoading = createSelector(_selectBooking, (state: IBookingState) => state.isLoading);
export const selectOverlapIsLoading = createSelector(_selectBooking, (state: IBookingState) => state.isLoadingOverlap);
export const selectCurrentDate = createSelector(_selectBooking, (state: IBookingState) => state.currentDate);

export const selectCurrentDateRange = createSelector(_selectBooking, (state: IBookingState) => {
  const start = moment(state.currentDate).startOf('day');
  let end = moment(state.currentDate).endOf('day');

  if (state.isWeekView) {
    start.startOf('isoWeek').startOf('day');
    end = start.clone().endOf('isoWeek').endOf('day');
  }

  return {start: start.utc().toISOString(), end: end.utc().toISOString()};
});

export const selectCalendar = createSelector(_selectBooking, (state: IBookingState) => state.calendar);
export const selectBookingSettings = createSelector(_selectBooking, (state: IBookingState) => state.settings);
export const selectCurrentBooking = createSelector(_selectBooking, (state: IBookingState) => state.currentBooking);
export const selectOverlap = createSelector(_selectBooking, (state: IBookingState) => state.overlap);
export const selectCustomerBookings = createSelector(_selectBooking, (state: IBookingState) => state.customerBookings);
export const selectIsWeekView = createSelector(_selectBooking, (state: IBookingState) => state.isWeekView);
export const selectCurrentCalendarUser = createSelector(_selectBooking, (state: IBookingState) => state.selectedCalendarUser);
export const selectWaitlist = createSelector(_selectBooking, (state: IBookingState) => state.waitlist);
