import {TuiLanguageEditor} from '@taiga-ui/i18n/interfaces';

export const TUI_DANISH_LANGUAGE_ADDON_EDITOR: TuiLanguageEditor = {
    colorSelectorModeNames: [`Solid color`, `Gradient`],
    toolbarTools: {
        undo: `Undo`,
        redo: `Redo`,
        font: `Font`,
        fontStyle: `Font style`,
        fontSize: `Font size`,
        bold: `Bold`,
        italic: `Italic`,
        underline: `Underline`,
        strikeThrough: `Strike through`,
        justify: `Justify`,
        justifyLeft: `Justify left`,
        justifyCenter: `Justify center`,
        justifyRight: `Justify right`,
        justifyFull: `Justify full`,
        list: `List`,
        indent: `Indent`,
        outdent: `Outdent`,
        unorderedList: `Unordered list`,
        orderedList: `Ordered list`,
        quote: `Quote`,
        foreColor: `Color`,
        backColor: `Background color`,
        hiliteColor: `Highlight color`,
        clear: `Clear`,
        link: `Link`,
        attach: `Attach file`,
        tex: `Insert TeX`,
        code: `Code`,
        image: `Insert image`,
        insertHorizontalRule: `Insert horizontal rule`,
        superscript: `Superscript`,
        subscript: `Subscript`,
        insertTable: `Insert table`,
        insertGroup: `Insert group`,
        hiliteGroup: `Hilite group`,
        removeGroup: `Remove group`,
        insertAnchor: `Insert anchor`,
        mergeCells: `Merge cells`,
        splitCells: `Split cells`,
        rowsColumnsManaging: `Managing rows and columns`,
        cellColor: `Cell color`,
        setDetails: `Details`,
        removeDetails: `Remove details`,
    },
    editorEditLink: {
        urlExample: `example.com`,
        anchorExample: `anchor`,
    },
    editorTableCommands: [
        [`Insert column before`, `Insert column after`],
        [`Insert row before`, `Insert row after`],
        [`Delete column`, `Delete row`],
    ],
    editorCodeOptions: [`Code in the text`, `Code in block`],
    editorFontOptions: {
        small: `Small`,
        large: `Large`,
        normal: `Normal`,
        title: `Title`,
        subtitle: `Subtitle`,
    },
};
