import {TuiLanguageCore} from '@taiga-ui/i18n/interfaces';

import {TUI_DANISH_LANGUAGE_COUNTRIES} from './countries';

export const TUI_DANISH_LANGUAGE_CORE: TuiLanguageCore = {
    months: [
        `Januar`,
        `Februar`,
        `Marts`,
        `April`,
        `Maj`,
        `Juni`,
        `Juli`,
        `August`,
        `September`,
        `Oktober`,
        `November`,
        `December`,
    ],
    close: `Close`,
    nothingFoundMessage: `Nothing found`,
    defaultErrorMessage: `Value is invalid`,
    spinTexts: [`Previous`, `Next`],
    shortWeekDays: [`Man`, `Tir`, `Ons`, `Tor`, `Fre`, `Lør`, `Søn`],
    countries: TUI_DANISH_LANGUAGE_COUNTRIES,
};
