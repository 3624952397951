import {EffectsModule} from "@ngrx/effects";
import {CommonModule} from "@angular/common";
import {StoreModule} from "@ngrx/store";
import {StatisticsEffects} from "@core/store/statistics/statistics.effects";
import {NgModule} from "@angular/core";
import {statisticsReducers} from "@core/store/statistics/statistics.reducers";

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('statistics', statisticsReducers),
        EffectsModule.forFeature([StatisticsEffects]),
    ],
    providers: [StatisticsEffects],
})
export class StatisticsStoreModule {
}
