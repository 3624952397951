import {EffectsModule} from "@ngrx/effects";
import {CommonModule} from "@angular/common";
import {StoreModule} from "@ngrx/store";
import {OrderEffects} from "@core/store/order/order.effects";
import {NgModule} from "@angular/core";
import {orderReducers} from "@core/store/order/order.reducers";

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('order', orderReducers),
        EffectsModule.forFeature([OrderEffects]),
    ],
    providers: [OrderEffects],
})
export class OrderStoreModule {
}
