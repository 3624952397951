import { Injectable } from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, mergeMap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {
  ENavigationActions,
  NavigateTo, SetIsAdminMode,
} from "@core/store/navigation/navigation.actions";

@Injectable()
export class NavigationEffects {

	constructor(
		private router: Router,
		private actions$: Actions,
		private route: ActivatedRoute,

	) {}

	public onNavigateTo$ = createEffect(() => this.actions$.pipe(ofType<NavigateTo>(ENavigationActions.NavigateTo),
		map((navigateTo) => {
			const _ = this.router.navigate(navigateTo.commands, { queryParams: navigateTo.queryParams });
		})
	), {dispatch: false});

  public onSetIsAdminMode = createEffect(() => this.actions$.pipe(ofType<SetIsAdminMode>(ENavigationActions.SetIsAdminMode),
    mergeMap((action) => {
      if (action.isAdminMode === true) {
        return [];
      }

      const routesDeactivated = [
        'portal/dashboard',
        'portal/settings',
        'portal/economy',
        'portal/statistics',
      ];

      const isRouteDeactivated = routesDeactivated.some(route => this.router.url.includes(route));

      return isRouteDeactivated ? [new NavigateTo(['/portal/bookings'])] : [];
    })
  ));
}
