import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "@env/environment";
import {IChartModel} from "@models/chart.model";
import {ISMSUsageModel} from "@models/sms-usage.model";
import {IOverviewStatsModel} from "@models/overview-stats.model";

@Injectable({
  providedIn: 'root',
})
export class StatisticsService {

  constructor(
    private http: HttpClient,
  ) {}

  public getDashboardRevenue(from_date: string, to_date: string, group_by?: string | null): Observable<IChartModel> {
    return this.http.get<IChartModel>(`${environment.api_url}/statistics/dashboard-revenue-chart`, {
      params: {
        from_date,
        to_date,
        group_by,
      }
    });
  }

  public getSMSUsage(from_date: string, to_date: string): Observable<ISMSUsageModel> {
    return this.http.get<ISMSUsageModel>(`${environment.api_url}/sms-reports`, {
      params: {
        from_date,
        to_date,
      }
    });
  }

  public getOverviewStats(from_date: string, to_date: string, group_by: string): Observable<IOverviewStatsModel> {
    return this.http.get<IOverviewStatsModel>(`${environment.api_url}/statistics/dashboard`, {
      params: {
        from_date,
        to_date,
        group_by,
      }
    });
  }

  public getChainRevenue(from_date: string, to_date: string, group_by: string): Observable<IChartModel> {
    return this.http.get<IChartModel>(`${environment.api_url}/statistics/chain-revenue-chart`, {
      params: {
        from_date,
        to_date,
        group_by,
      }
    });
  }
}

