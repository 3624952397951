import { TranslateLoader } from "@ngx-translate/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "@env/environment";

export class CustomTranslateLoader implements TranslateLoader {
	constructor(private http: HttpClient) {}

	public getTranslation(lang: string): Observable<any> {
		return this.http.get(`assets/i18n/${lang}.json?cb=${environment.hash}`);
	}
}
