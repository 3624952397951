<div class="flex flex-col gap-6 p-6 bg-brand-gray-50 {{showInput ? 'min-w-[500px]' : ''}}">
  <button class="right-4 top-4 absolute p-3 z-50" (click)="cancel()">
    <img src="assets/icon/x.svg" alt="icon">
  </button>

  <div class="flex flex-col gap-2 max-w-[430px]">
    <h2 *ngIf="title">{{title}}</h2>
    <p *ngIf="message" class="text-brand-gray-400">{{message}}</p>
  </div>

  <form *ngIf="showInput" [formGroup]="validationForm" (ngSubmit)="submitForm()">
    <input *ngIf="showInput" formControlName="input" class="box-input w-full" [placeholder]="inputPlaceholder">
    <div *ngIf="validationForm.get('input').invalid && (validationForm.get('input').dirty || validationForm.get('input').touched)">
      <p class="text-brand-error-500 font-bold" *ngIf="validationForm.get('input').hasError('required')">Email er påkrævet</p>
      <p class="text-brand-error-500 font-bold" *ngIf="validationForm.get('input').hasError('email')">Ugyldig email</p>
    </div>
  </form>


  <div class="flex gap-4 w-full justify-between">
    <button class="tertiary-button medium-btn !w-full !aspect-auto" (click)="cancel()">
      Annuller
    </button>

    <button [disabled]="showInput && validationForm.invalid" class="secondary-button medium-btn !w-full" (click)="confirm()">
      {{action}}
    </button>
  </div>
</div>
