import {PaginationHolder} from "@models/pagination.model";
import {IVoucherModel} from "@models/voucher.model";
import {IPaymentIntentModel} from "@models/payment-intent.model";

export interface IVoucherState {
  isLoading: boolean;
  vouchers?: PaginationHolder<IVoucherModel>;
  voucher?: IVoucherModel;
  voucherIntents?: PaginationHolder<IPaymentIntentModel>;
  createdVoucher?: IVoucherModel;
}

export const initialVoucherState: IVoucherState = {
  isLoading: false,
}
