import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IRootState } from '@core/store/root.state';
import { catchError, mergeMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { LoadError } from '@core/store/error/error.actions';
import { BillingService } from '@core/store/billing/billing.service';
import {
  AddCreditCardFromToken,
  AddCreditCardFromTokenSuccess,
  EBillingActions,
  FetchBillingSettings,
  FetchBillingSettingsSuccess,
  FetchInvoices,
  FetchInvoicesSuccess,
} from '@core/store/billing/billing.actions';

@Injectable()
export class BillingEffects {
  constructor(
    private actions$: Actions,
    private store: Store<IRootState>,
    private billingService: BillingService
  ) {}

  public onAddCreditCardFromToken = createEffect(() =>
    this.actions$.pipe(
      ofType<AddCreditCardFromToken>(EBillingActions.AddCreditCardFromToken),
      mergeMap(action =>
        from(this.billingService.postAddCard(action.token)).pipe(
          mergeMap(token => [new AddCreditCardFromTokenSuccess(), new FetchBillingSettings()]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onFetchBillingSettings = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchBillingSettings>(EBillingActions.FetchBillingSettings),
      mergeMap(action =>
        from(this.billingService.getBillingSettings()).pipe(
          mergeMap(settings => [new FetchBillingSettingsSuccess(settings)]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onFetchInvoices = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchInvoices>(EBillingActions.FetchInvoices),
      mergeMap(action =>
        from(this.billingService.getInvoices()).pipe(
          mergeMap(invoices => [new FetchInvoicesSuccess(invoices)]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );
}
