import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "@env/environment";
import {IReportModel} from "@models/report.model";
import {PaginationHolder} from "@models/pagination.model";
import {IPaymentMethodModel} from "@models/payment-method.model";
import {ISaleModel} from "@models/sale.model";

@Injectable({
  providedIn: 'root',
})
export class EconomyService {
  constructor(
    private http: HttpClient,
  ) {}

  public getReport(from_date: string, to_date: string): Observable<IReportModel> {
    return this.http.get<IReportModel>(`${environment.api_url}/reports`, {
      params : {
        from_date,
        to_date,
      }
    });
  }

  public getPaymentMethods(): Observable<PaginationHolder<IPaymentMethodModel>> {
    return this.http.get<PaginationHolder<IPaymentMethodModel>>(`${environment.api_url}/payment-methods`);
  }

  public createPaymentMethod(name: string, type: string): Observable<IPaymentMethodModel> {
    return this.http.post<IPaymentMethodModel>(`${environment.api_url}/payment-methods`, { name, type });
  }

  public editPaymentMethod(id: string, name: string, type: string): Observable<IPaymentMethodModel> {
    return this.http.patch<IPaymentMethodModel>(`${environment.api_url}/payment-methods/${id}`, { name, type });
  }

  public deletePaymentMethod(id: string): Observable<any> {
    return this.http.delete<any>(`${environment.api_url}/payment-methods/${id}`);
  }

  public getSales(from_date: string, to_date: string, next_page?: number): Observable<PaginationHolder<ISaleModel>> {
    const params = {from_date, to_date, 'per_page': 20};

    if (next_page) {
      params['page'] = next_page;
    }

    return this.http.get<PaginationHolder<ISaleModel>>(`${environment.api_url}/orders/booking-details`, { params });
  }

  public getSaleById(id: string): Observable<ISaleModel> {
    return this.http.get<ISaleModel>(`${environment.api_url}/orders/${id}/booking-details`);
  }
}

