import {ENavigationActions, NavigationActions} from "@core/store/navigation/navigation.actions";
import {INavigationState, initialNavigationState} from "@core/store/navigation/navigation.state";
import {ErrorActions} from "@core/store/error/error.actions";
import {AuthenticationActions} from "@core/store/authentication/authentication.actions";
import produce from "immer";

export const navigationReducers = (state = initialNavigationState, action: NavigationActions | ErrorActions | AuthenticationActions): INavigationState => {
  let nextState: INavigationState;

  switch (action.type) {

    case ENavigationActions.SetIsSidebarExpanded: {
      nextState = produce(state, (draftState) => {
        draftState.isSidebarExpanded = action.isExpanded;
      });

      return nextState;
    }

    case ENavigationActions.SetIsAdminMode:
      nextState = produce(state, (draftState) => {
        draftState.isAdminMode = action.isAdminMode;
      });

      return nextState;

    default:
      return state;
  }
};
