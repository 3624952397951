import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ICreateBookingRequestModel} from "@models/request/create-booking.request.model";
import {Observable} from "rxjs";
import {environment} from "@env/environment";
import {ISignedURLModel} from "@models/signed-url.model";
import {switchMap} from "rxjs/operators";

@Injectable({
	providedIn: 'root',
})
export class UploadService {
	constructor(private http: HttpClient) {}

	public uploadImageWithSignedURL(file: File): Observable<ISignedURLModel> {
		return this.generateSignedURL().pipe(switchMap((signedURL: ISignedURLModel) => this.uploadImage(file, signedURL).pipe(
				switchMap(() =>
					this.finalizeSignedURL(signedURL)))
			)
		);
	}

	private generateSignedURL(): Observable<ISignedURLModel> {
		return this.http.post<ISignedURLModel>(`${environment.api_url}/file-upload-intents`, {
			"mime_type": "image/jpg"
		});
	}

	private uploadImage(file: File, signed_URL: ISignedURLModel): Observable<any> {
		return this.http.put<any>(`${signed_URL.signed_url}`, file);
	}

	private finalizeSignedURL(signed_URL: ISignedURLModel): Observable<any> {
		return this.http.patch<any>(`${environment.api_url}/file-upload-intents/${signed_URL.id}/finalize`, {});
	}
}
