import { Action } from '@ngrx/store';
import { PaginationHolder } from '@models/pagination.model';
import { IBookingHourModel } from '@models/booking-hour.model';
import { ISMSSettingsModel } from '@models/sms-settings.model';
import { IUpdateSmsSettingsRequestModel } from '@models/request/update-sms-settings.request.model';
import { ISMSTemplateModel } from '@models/sms-template.model';
import { IUpdateSMSTemplateRequestModel } from '@models/request/update-sms-template.request.model';

export enum ESettingsActions {
  FetchBookingHours = '[SETTINGS] Fetch Booking Hours',
  FetchBookingHoursSuccess = '[SETTINGS] Fetch Booking Hours Success',

  ChangeBookingHours = '[SETTINGS] Change Booking Hours',
  ChangeBookingHoursSuccess = '[SETTINGS] Change Booking Hours Success',

  FetchSMSSettings = '[SETTINGS] Fetch SMS Settings',
  FetchSMSSettingsSuccess = '[SETTINGS] Fetch SMS Settings Success',

  UpdateSMSSettings = '[SETTINGS] Update SMS Settings',
  UpdateSMSSettingsSuccess = '[SETTINGS] Update SMS Settings Success',

  FetchSMSTemplates = '[SETTINGS] Fetch SMS Templates',
  FetchSMSTemplatesSuccess = '[SETTINGS] Fetch SMS Templates Success',

  UpdateSMSTemplate = '[SETTINGS] Update SMS Template',
  UpdateSMSTemplateSuccess = '[SETTINGS] Update SMS Template Success',

  FetchSMSPrice = '[SETTINGS] Fetch SMS Price',
  FetchSMSPriceSuccess = '[SETTINGS] Fetch SMS Price Success',

  SetSidebarVisibility = '[SETTINGS] Set Sidebar Visibility',
}

// Fetch Booking Hours

export class FetchBookingHours implements Action {
  public readonly type = ESettingsActions.FetchBookingHours;

  constructor() {}
}

export class FetchBookingHoursSuccess implements Action {
  public readonly type = ESettingsActions.FetchBookingHoursSuccess;

  constructor(public hours: PaginationHolder<IBookingHourModel>) {}
}

// Create Booking Hours

export class ChangeBookingHours implements Action {
  public readonly type = ESettingsActions.ChangeBookingHours;

  constructor(
    public toCreate: IBookingHourModel[],
    public toUpdate: IBookingHourModel[],
    public toDelete: IBookingHourModel[]
  ) {}
}

export class ChangeBookingHoursSuccess implements Action {
  public readonly type = ESettingsActions.ChangeBookingHoursSuccess;

  constructor() {}
}

// Fetch SMS Settings

export class FetchSMSSettings implements Action {
  public readonly type = ESettingsActions.FetchSMSSettings;

  constructor() {}
}

export class FetchSMSSettingsSuccess implements Action {
  public readonly type = ESettingsActions.FetchSMSSettingsSuccess;

  constructor(public settings: ISMSSettingsModel) {}
}

// Update SMS Settings

export class UpdateSMSSettings implements Action {
  public readonly type = ESettingsActions.UpdateSMSSettings;

  constructor(public reqData: IUpdateSmsSettingsRequestModel) {}
}

export class UpdateSMSSettingsSuccess implements Action {
  public readonly type = ESettingsActions.UpdateSMSSettingsSuccess;

  constructor() {}
}

// Fetch SMS Templates

export class FetchSMSTemplates implements Action {
  public readonly type = ESettingsActions.FetchSMSTemplates;

  constructor() {}
}

export class FetchSMSTemplatesSuccess implements Action {
  public readonly type = ESettingsActions.FetchSMSTemplatesSuccess;

  constructor(public templates: PaginationHolder<ISMSTemplateModel>) {}
}

// Update SMS Template

export class UpdateSMSTemplate implements Action {
  public readonly type = ESettingsActions.UpdateSMSTemplate;

  constructor(
    public id: string,
    public reqData: IUpdateSMSTemplateRequestModel
  ) {}
}

export class UpdateSMSTemplateSuccess implements Action {
  public readonly type = ESettingsActions.UpdateSMSTemplateSuccess;

  constructor() {}
}

// Fetch SMS Settings

export class FetchSMSPrice implements Action {
  public readonly type = ESettingsActions.FetchSMSPrice;

  constructor() {}
}

export class FetchSMSPriceSuccess implements Action {
  public readonly type = ESettingsActions.FetchSMSPriceSuccess;

  constructor() {}
}

// Set Sidebar Visibility

export class SetSidebarVisibility implements Action {
  public readonly type = ESettingsActions.SetSidebarVisibility;

  constructor(public isVisible: boolean) {}
}

export type SettingsActions =
  | FetchBookingHours
  | FetchBookingHoursSuccess
  | ChangeBookingHours
  | ChangeBookingHoursSuccess
  | FetchSMSSettings
  | FetchSMSSettingsSuccess
  | UpdateSMSSettings
  | UpdateSMSSettingsSuccess
  | FetchSMSTemplates
  | FetchSMSTemplatesSuccess
  | UpdateSMSTemplate
  | UpdateSMSTemplateSuccess
  | FetchSMSPrice
  | FetchSMSPriceSuccess
  | SetSidebarVisibility;
