<div class="flex flex-col p-6 rounded-lg gap-10 max-w-full">
  <div class="flex gap-4 items-center">
    <div class="flex items-center justify-center bg-brand-gray-200 rounded-full w-24 h-24 aspect-square">
      <img class="w-8 h-8 svg-fill-gray-400" src="assets/icon/lock.svg">
    </div>

    <div class="flex flex-col gap-1">
      <h2 class="font-bold">Indtast PIN-kode</h2>
      <p class="font-medium text-brand-gray-400">Skift til administrator-tilstand</p>
    </div>
  </div>

  <div class="flex flex-col gap-2 max-w-full">
    <p class="font-medium">Pin-kode</p>

    <div class="flex gap-4">

      <div class="flex gap-4 w-full max-w-full">
        <input #pin1 id="pin1" type="text" pattern="\d*" maxlength="1" placeholder="0" (input)="didEnterPinPart($event)">
        <input #pin2 id="pin2" type="text" pattern="\d*" maxlength="1" placeholder="0" (input)="didEnterPinPart($event)">
        <input #pin3 id="pin3" type="text" pattern="\d*" maxlength="1" placeholder="0" (input)="didEnterPinPart($event)">
        <input #pin4 id="pin4" type="text" pattern="\d*" maxlength="1" placeholder="0" (input)="didEnterPinPart($event)">
      </div>

    </div>
  </div>

  <div class="flex justify-end">
    <button class="option-button small-btn" (click)="didClickCancel()">
      Annuller
    </button>
  </div>
</div>
