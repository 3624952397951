import {ActionReducer} from '@ngrx/store';
import {EHydrateActions, HydrateActions} from './hydration.actions';
import {initialAppState, IRootState} from '@app/core/store/root.state';
import produce from 'immer';

export const hydrationMetaReducer = (reducer: ActionReducer<IRootState>): ActionReducer<IRootState> => (state: IRootState, action: HydrateActions): IRootState => {
		let nextState;

		switch (action.type) {
			case EHydrateActions.HydrateSuccess:
				nextState = produce(action.state, (draftState) => {});

				return nextState;
      case EHydrateActions.ClearHydration:
        nextState = produce(initialAppState, (draftState) => {});

        return nextState;

			default:
				return reducer(state, action);
		}
	};
