import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { IUserModel } from '@app/core/models/user.model';
import { IRegisterAccountRequestModel } from '@models/request/register-account.request.model';
import { IAccountModel } from '@models/account.model';
import { IPreAuthModel } from '@models/pre-auth.model';
import { map } from 'rxjs/operators';
import { PaginationHolder } from '@models/pagination.model';
import { IUpdateAccountRequestModel } from '@models/request/update-account.request.model';
import { ICreateUserRequestModel } from '@models/request/create-user.request.model';
import { IAccountSettingsModel } from '@models/account-settings.model';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(private http: HttpClient) {}

  public createAccount(reqModel: IRegisterAccountRequestModel): Observable<any> {
    return this.http.post<any>(`${environment.api_url}/public/accounts`, reqModel);
  }

  public preAuth(email: string, password: string): Observable<IPreAuthModel[]> {
    return this.http.post<any>(`${environment.api_url}/public/accounts/login`, {
      email,
      password,
    }); // .pipe(map((response) => response.accounts));
  }

  public login(email: string, password: string, account: IPreAuthModel): Observable<IAccountModel> {
    return this.http
      .post<IAccountModel>(
        `${environment.api_url}/public/accounts/${account.id}/login`,
        {
          email,
          password,
        },
        { observe: 'response' }
      )
      .pipe(
        map(response => {
          const userModel: IAccountModel = {
            ...response.body,
            user: {
              ...response.body.user,
              token: response.headers.get('authorization'),
            },
          };

          return userModel;
        })
      );
  }

  public requestResetPassword(email: string): Observable<any> {
    return this.http.post<any>(`${environment.api_url}/public/users/recover-password`, {
      email,
    });
  }

  public resetPassword(password: string, token: string): Observable<any> {
    return this.http.post<any>(`${environment.api_url}/public/users/recover-password-confirmation`, {
      password,
      token,
    });
  }

  public fetchUser(id: string): Observable<IUserModel> {
    return this.http.get<IUserModel>(`${environment.api_url}/users/${id}`);
  }

  public createUser(reqModel: ICreateUserRequestModel): Observable<any> {
    return this.http.post<any>(`${environment.api_url}/users`, reqModel);
  }

  public patchUser(id: string, reqModel: ICreateUserRequestModel): Observable<any> {
    return this.http.patch<any>(`${environment.api_url}/users/${id}`, reqModel);
  }

  public fetchUsers(): Observable<PaginationHolder<IUserModel>> {
    return this.http.get<PaginationHolder<IUserModel>>(`${environment.api_url}/users`, {
      params: {
        per_page: 100,
      },
    });
  }

  public fetchAccount(account_id: string): Observable<IAccountModel> {
    return this.http.get<IAccountModel>(`${environment.api_url}/accounts/${account_id}`);
  }

  public fetchAccountSettings(): Observable<IAccountSettingsModel> {
    return this.http.get<IAccountSettingsModel>(`${environment.api_url}/account-settings`);
  }

  public patchAccountSettings(reqData: IAccountSettingsModel): Observable<IAccountSettingsModel> {
    return this.http.patch<IAccountSettingsModel>(`${environment.api_url}/account-settings`, reqData);
  }

  public updateAccount(account_id: string, reqModel: IUpdateAccountRequestModel): Observable<any> {
    return this.http.patch<any>(`${environment.api_url}/accounts/${account_id}`, reqModel);
  }

  public switchAccount(account_id: string): Observable<IAccountModel> {
    return this.http
      .post<IAccountModel>(
        `${environment.api_url}/users/switch-account`,
        {
          account_id,
        },
        { observe: 'response' }
      )
      .pipe(
        map(response => {
          const userModel: IAccountModel = {
            ...response.body,
            user: {
              ...response.body.user,
              token: response.headers.get('authorization'),
            },
          };

          return userModel;
        })
      );
  }
}
