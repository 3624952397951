import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownComponent } from '@app/shared/components/dropdown/dropdown.component';
import { TuiHostedDropdownModule } from '@taiga-ui/core';
import { TuiActiveZoneModule } from '@taiga-ui/cdk';

@NgModule({
  declarations: [DropdownComponent],
  imports: [CommonModule, TuiHostedDropdownModule, TuiActiveZoneModule],
  exports: [DropdownComponent],
})
export class DropdownModule {}
