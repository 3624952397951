import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {Store} from "@ngrx/store";
import {getCurrentState} from "@app/functions";
import {IRootState} from "@core/store/root.state";

@Injectable()
export class AuthGuard implements CanActivate {
	public constructor(
		private store: Store<IRootState>,
		private router: Router,
	) {
	}

	public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		return new Promise((resolve, reject) => {
			const user = getCurrentState(this.store).authentication.user;

			if (user && user.token) {
				return resolve(true);
			}

			this.router.navigate(['/login']);

			return resolve(false);
		});
	}
}
