import { LetDirective } from "@app/shared/directives/if-let/if-let.directive";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

@NgModule({
	declarations: [LetDirective],
	imports: [CommonModule],
	exports: [LetDirective],
})
export class IfLetModule {}
