export enum ENotification {
	CATEGORY_UPDATED,
  PRODUCT_UPDATED,
  CUSTOMER_UPDATED,
  PAYMENT_METHOD_UPDATED,
  USER_UPDATED,
  BOOKING_UPDATED,
  BOOKING_DELETED,
  ORDER_UPDATED,
  BLOCKING_UPDATED,
  VOUCHER_UPDATED,
  JOURNAL_UPDATED,
}
