import produce from "immer";
import {EErrorActions, ErrorActions} from "@app/core/store/error/error.actions";
import {IStatisticsState, initialStatisticsState} from "@app/core/store/statistics/statistics.state";
import {StatisticsActions, EStatisticsActions} from "@app/core/store/statistics/statistics.actions";

export const statisticsReducers = (state: IStatisticsState = initialStatisticsState, action: StatisticsActions | ErrorActions): IStatisticsState => {
  let nextState;

  switch (action.type) {
    case EStatisticsActions.GetDashboardRevenue:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = true;
      });

      return nextState;
    case EStatisticsActions.GetDashboardRevenueSuccess:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = false;
        draftState.dashboardRevenue = action.data;
      });

      return nextState;

    // Fetch SMS Usage

    case EStatisticsActions.FetchSMSUsage:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = true;
      });

      return nextState;
    case EStatisticsActions.FetchSMSUsageSuccess:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = false;
        draftState.smsUsage = action.usage;
      });

      return nextState;

    // Update Statistics Date Range

    case EStatisticsActions.UpdateStatisticsDateRange:
      nextState = produce(state, (draftState) => {
        draftState.statisticsDateStart = action.from_date;
        draftState.statisticsDateEnd = action.to_date;
      });

      return nextState;

    // Fetch Overview Stats

    case EStatisticsActions.FetchOverviewStatistics:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = true;
      });

      return nextState;
    case EStatisticsActions.FetchOverviewStatisticsSuccess:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = false;
        draftState.overviewStats = action.stats;
      });

      return nextState;

    // Fetch Chain Revenue Chart

    case EStatisticsActions.FetchChainRevenueChart:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = true;
      });

      return nextState;
    case EStatisticsActions.FetchChainRevenueChartSuccess:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = false;
        draftState.chainRevenue = action.data;
      });

      return nextState;

    // ERROR

    case EErrorActions.LoadError:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = false;
      });

      return nextState;
    default:
      return state;
  }
};

