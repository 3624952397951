import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {PaginationHolder} from "@models/pagination.model";
import {IVoucherModel} from "@models/voucher.model";
import {environment} from "@env/environment";
import {ICreateVoucherRequestModel} from "@models/request/create-voucher.request.model";
import {IPaymentIntentModel} from "@models/payment-intent.model";
import {IUpdateVoucherRequestModel} from "@models/request/update-voucher.request.model";

@Injectable({
  providedIn: 'root',
})
export class VoucherService {
  constructor(
    private http: HttpClient,
  ) {}

  public getVouchers(search?: string, next_page?: number): Observable<PaginationHolder<IVoucherModel>> {
    const params = {};

    if (search) {
      params['gift_card_code'] = search;
    }

    if (next_page) {
      params['page'] = next_page;
    }

    return this.http.get<PaginationHolder<IVoucherModel>>(`${environment.api_url}/gift-cards`, {
      params,
    });
  }

  public getVoucher(id: string): Observable<IVoucherModel> {
    return this.http.get<IVoucherModel>(`${environment.api_url}/gift-cards/${id}`);
  }

  public getVoucherIntents(id: string): Observable<PaginationHolder<IPaymentIntentModel>> {
    return this.http.get<PaginationHolder<IPaymentIntentModel>>(`${environment.api_url}/gift-cards/${id}/payment-intents`);
  }

  public createVoucher(reqData: ICreateVoucherRequestModel): Observable<IVoucherModel> {
    return this.http.post<IVoucherModel>(`${environment.api_url}/gift-cards`, reqData);
  }

  public updateVoucher(id: string, reqData: IUpdateVoucherRequestModel): Observable<any> {
    return this.http.patch<any>(`${environment.api_url}/gift-cards/${id}`, reqData);
  }

  public sendVoucher(id: string, email: string): Observable<any> {
    return this.http.post<any>(`${environment.api_url}/gift-cards/${id}/send-by-email`, { email });
  }
}
