import {IOrderModel} from "@models/order.model";

export interface IOrderState {
  isLoading: boolean;
  order: IOrderModel;
}

export const initialOrderState: IOrderState = {
  isLoading: false,
  order: null
}
