import { IUserModel } from "@app/core/models/user.model";
import {IPreAuthModel} from "@models/pre-auth.model";
import {IAccountModel} from "@models/account.model";
import {PaginationHolder} from "@models/pagination.model";
import {IAccountSettingsModel} from "@models/account-settings.model";

export interface IAuthenticationState {
	isLoading: boolean;
	accounts: IPreAuthModel[];
	account?: IAccountModel;
  settings?: IAccountSettingsModel;
	user?: IUserModel;
	users: IUserModel[];
	usersCursor: PaginationHolder<IUserModel>;
  isOnboardingSettingsCompleted: boolean;
  isOnboardingLinkCompleted: boolean;
  isOnboardingCompleted: boolean;
}

export const initialAuthenticationState: IAuthenticationState = {
	isLoading: false,
	accounts: [],
	users: [],
	usersCursor: null,
  isOnboardingSettingsCompleted: false,
  isOnboardingLinkCompleted: false,
  isOnboardingCompleted: false,
}
