import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from '@app/app.module';
import {environment} from "@env/environment";
import {enableProdMode} from "@angular/core";
import {EEnvironment} from "@enums/environment.enum";

if (environment.env === EEnvironment.production) {
	enableProdMode();
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch((err) => console.error(err));
