import {Action} from "@ngrx/store";
import {IOrderModel} from "@models/order.model";
import {IUpdateOrderLinesRequestModel} from "@models/request/update-order-lines.request.model";
import {ILineModel} from "@models/line.model";
import {ICreateOrderRequestModel} from "@models/request/create-order.request.model";

export enum EOrderActions {
  FetchOrder = '[ORDER] Fetch Order',
  FetchOrderSuccess = '[ORDER] Fetch Order Success',

  CreateOrder = '[ORDER] Create Order',
  CreateOrderSuccess = '[ORDER] Create Order Success',

  UpdateOrderLines = '[ORDER] Update Order Lines',
  UpdateOrderLinesSuccess = '[ORDER] Update Order Lines Success',

  AddDiscount = '[ORDER] Add Discount',
  AddDiscountSuccess = '[ORDER] Add Discount Success',

  ClearOrder = '[ORDER] Clear Order'
}

// Fetch Order

export class FetchOrder implements Action {
  public readonly type = EOrderActions.FetchOrder;

  constructor(public id: string) {}
}

export class FetchOrderSuccess implements Action {
  public readonly type = EOrderActions.FetchOrderSuccess;

  constructor(public order: IOrderModel) {}
}

// Create Order

export class CreateOrder implements Action {
  public readonly type = EOrderActions.CreateOrder;

  constructor(public reqData: ICreateOrderRequestModel, public booking_id?: string) {}
}

export class CreateOrderSuccess implements Action {
  public readonly type = EOrderActions.CreateOrderSuccess;

  constructor(public order: IOrderModel) {}
}

// Update Order Lines

export class UpdateOrderLines implements Action {
  public readonly type = EOrderActions.UpdateOrderLines;

  constructor(public id: string, public reqData: IUpdateOrderLinesRequestModel) {}
}

export class UpdateOrderLinesSuccess implements Action {
  public readonly type = EOrderActions.UpdateOrderLinesSuccess;

  constructor() {}
}

// Add Discount

export class AddDiscount implements Action {
  public readonly type = EOrderActions.AddDiscount;

  constructor(public order_id: string, public lines: ILineModel[]) {}
}

export class AddDiscountSuccess implements Action {
  public readonly type = EOrderActions.AddDiscountSuccess;

  constructor() {}
}

// Clear Order

export class ClearOrder implements Action {
  public readonly type = EOrderActions.ClearOrder;

  constructor() {}
}

export type OrderActions =
  FetchOrder | FetchOrderSuccess |
  CreateOrder | CreateOrderSuccess |
  UpdateOrderLines | UpdateOrderLinesSuccess |
  AddDiscount | AddDiscountSuccess |
  ClearOrder;
