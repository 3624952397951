import {Store} from "@ngrx/store";
import {IRootState} from "@app/core/store/root.state";
import {IAccountModel} from "@models/account.model";
import {LocalStorageService} from "@services/local-storage/local-storage.service";
import {ELocalStorageKeys} from "@enums/local-storage.enum";
import {NavigateTo} from "@core/store/navigation/navigation.actions";
import {LoginSuccess} from "@core/store/authentication/authentication.actions";
import jwt_decode from 'jwt-decode';
import * as moment from "moment";
import {SetCurrentDate} from "@core/store/booking/booking.actions";
import {getCurrentState} from "@app/functions";
import {Hydrate} from "@core/store/hydration/hydration.actions";
import {PusherService} from "@services/pusher/pusher.service";

function currentRouteIsPublic(publicAccessRoutes: string[]): boolean {
  return publicAccessRoutes.indexOf(window.location.pathname) >= 0;
}

export function appInitializer(localStorageService: LocalStorageService, store: Store<IRootState>, pusher: PusherService): () => Promise<any> {
	return (): Promise<any> => new Promise((resolve) => {
    store.dispatch(new Hydrate());

    const publicAccessRoutes = [
      '/login',
      '/forgot',
      '/new-password',
      '/signup',
    ];

    if (currentRouteIsPublic(publicAccessRoutes)) {
      return resolve(true);
    }

		const storedAccount: IAccountModel = localStorageService.getItem(ELocalStorageKeys.account);

		if (!storedAccount?.user.token || !storedAccount) {
			store.dispatch(new NavigateTo(['/login']));

			return resolve(false);
		}

		const jwt = storedAccount.user.token;

		const decoded = jwt_decode(jwt);

		const tokenValid: boolean = decoded['exp'] > new Date().getTime() / 1000;

		if (!tokenValid) {
			store.dispatch(new NavigateTo(['/login']));

			return resolve(false);
		}

		store.dispatch(new LoginSuccess(storedAccount));
    pusher.initPusher(storedAccount);

    const currentDate = getCurrentState(store).booking.currentDate;

    if (!moment(currentDate).isSame(new Date(), 'day')) {
      store.dispatch(new SetCurrentDate(new Date()));
    }

		return resolve(true)
	});
}
