import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IRootState } from '@core/store/root.state';
import { catchError, mergeMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { LoadError } from '@core/store/error/error.actions';
import {
  CreateCustomer,
  CreateCustomerJournal,
  CreateCustomerJournalSuccess,
  CreateCustomerSuccess,
  ECustomerActions,
  FetchCustomerById,
  FetchCustomerByIdSuccess,
  FetchCustomerJournal,
  FetchCustomerJournalSuccess,
  FetchCustomers,
  FetchCustomerSMSHistory,
  FetchCustomerSMSHistorySuccess,
  FetchCustomersSuccess,
  SendCustomerSMS,
  SendCustomerSMSSuccess,
  UpdateCustomer,
  UpdateCustomerSuccess,
} from '@core/store/customer/customer.actions';
import { CustomerService } from '@core/store/customer/customer.service';
import { ENotification } from '@enums/notification.enum';
import { NotificationService } from '@services/notification/notification.service';
import { LogContentService } from '@app/shared/components/log-content/services/log-content.service';
import { ELogLevel } from '@app/shared/components/log-content/enums/log-level.enum';

@Injectable()
export class CustomerEffects {
  constructor(
    private actions$: Actions,
    private store: Store<IRootState>,
    private customerService: CustomerService,
    private notificationService: NotificationService,
    private logService: LogContentService
  ) {}

  public onFetchCustomers = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchCustomers>(ECustomerActions.FetchCustomers),
      mergeMap(action =>
        from(this.customerService.getCustomers(action.next_page, action.search)).pipe(
          mergeMap(customers => [new FetchCustomersSuccess(customers)]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onCreateCustomer = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateCustomer>(ECustomerActions.CreateCustomer),
      mergeMap(action =>
        from(this.customerService.postCustomer(action.reqModel)).pipe(
          mergeMap(customer => {
            this.notificationService.sendNotification(ENotification.CUSTOMER_UPDATED, null);

            return [new CreateCustomerSuccess(customer), new FetchCustomers()];
          }),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onUpdateCustomer = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateCustomer>(ECustomerActions.UpdateCustomer),
      mergeMap(action =>
        from(this.customerService.patchCustomer(action.id, action.reqModel)).pipe(
          mergeMap(customer => {
            this.notificationService.sendNotification(ENotification.CUSTOMER_UPDATED, null);

            return [new UpdateCustomerSuccess(customer), new FetchCustomers(), new FetchCustomerById(action.id)];
          }),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onFetchCustomerById = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchCustomerById>(ECustomerActions.FetchCustomerById),
      mergeMap(action =>
        from(this.customerService.getCustomerById(action.id)).pipe(
          mergeMap(customer => [new FetchCustomerByIdSuccess(customer)]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onFetchCustomerJournal = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchCustomerJournal>(ECustomerActions.FetchCustomerJournal),
      mergeMap(action =>
        from(this.customerService.getCustomerJournal(action.id)).pipe(
          mergeMap(data => [new FetchCustomerJournalSuccess(data)]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onCreateCustomerJournal = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateCustomerJournal>(ECustomerActions.CreateCustomerJournal),
      mergeMap(action =>
        from(this.customerService.createCustomerJournal(action.id, action.reqData)).pipe(
          mergeMap(() => {
            this.notificationService.sendNotification(ENotification.JOURNAL_UPDATED, null);

            return [new CreateCustomerJournalSuccess(), new FetchCustomerJournal(action.id)];
          }),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onFetchCustomerSMSHistory = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchCustomerSMSHistory>(ECustomerActions.FetchCustomerSMSHistory),
      mergeMap(action =>
        from(this.customerService.getCustomerSMSHistory(action.id)).pipe(
          mergeMap(data => [new FetchCustomerSMSHistorySuccess(data)]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onSendCustomerSMS = createEffect(() =>
    this.actions$.pipe(
      ofType<SendCustomerSMS>(ECustomerActions.SendCustomerSMS),
      mergeMap(action =>
        from(this.customerService.postCreateCustomerSMS(action.id, action.text)).pipe(
          mergeMap(data => {
            this.logService.logContent({
              level: ELogLevel.SUCCESS,
              description: 'SMS sendt',
            });

            return [new SendCustomerSMSSuccess(), new FetchCustomerSMSHistory(action.id)];
          }),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );
}
