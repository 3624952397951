import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PaginationHolder } from '@models/pagination.model';
import { environment } from '@env/environment';
import { ICustomerModel } from '@models/customer.model';
import { ICreateCustomerRequestModel } from '@models/request/create-customer.request.model';
import { IUpdateCustomerRequestModel } from '@models/request/update-customer.request.model';
import { ICreateCustomerJournalRequestModel } from '@models/request/create-customer-journal.request';
import { IJournalEntryModel } from '@models/journal-entry.model';
import { ICustomerSMSHistoryModel } from '@models/customer-sms-history.model';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  constructor(private http: HttpClient) {}

  public getCustomers(next_page?: number, search?: string): Observable<PaginationHolder<ICustomerModel>> {
    const params = { per_page: 50 };

    if (next_page) {
      params['page'] = next_page;
    }

    if (search) {
      params['name'] = search;
    }

    return this.http.get<PaginationHolder<ICustomerModel>>(`${environment.api_url}/customers`, { params });
  }

  public postCustomer(reqModel: ICreateCustomerRequestModel): Observable<ICustomerModel> {
    return this.http.post<ICustomerModel>(`${environment.api_url}/customers`, reqModel);
  }

  public patchCustomer(id: string, reqModel: IUpdateCustomerRequestModel): Observable<ICustomerModel> {
    return this.http.patch<ICustomerModel>(`${environment.api_url}/customers/${id}`, reqModel);
  }

  public getCustomerById(id: string): Observable<ICustomerModel> {
    return this.http.get<ICustomerModel>(`${environment.api_url}/customers/${id}`);
  }

  public getCustomerJournal(id: string): Observable<PaginationHolder<IJournalEntryModel>> {
    return this.http.get<PaginationHolder<IJournalEntryModel>>(`${environment.api_url}/customers/${id}/journals`);
  }

  public createCustomerJournal(id: string, reqData: ICreateCustomerJournalRequestModel): Observable<IJournalEntryModel> {
    return this.http.post<IJournalEntryModel>(`${environment.api_url}/customers/${id}/journals`, reqData);
  }

  public getCustomerSMSHistory(id: string): Observable<PaginationHolder<ICustomerSMSHistoryModel>> {
    return this.http.get<PaginationHolder<ICustomerSMSHistoryModel>>(`${environment.api_url}/customers/${id}/sms`);
  }

  public postCreateCustomerSMS(id: string, text: string): Observable<any> {
    return this.http.post<any>(`${environment.api_url}/customers/${id}/sms`, { text });
  }
}
