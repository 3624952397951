import {Action} from "@ngrx/store";
import {IVoucherModel} from "@models/voucher.model";
import {PaginationHolder} from "@models/pagination.model";
import {ICreateVoucherRequestModel} from "@models/request/create-voucher.request.model";
import {IPaymentIntentModel} from "@models/payment-intent.model";
import {IUpdateVoucherRequestModel} from "@models/request/update-voucher.request.model";

export enum EVoucherActions {
  FetchVouchers = '[VOUCHER] Fetch Vouchers',
  FetchVouchersSuccess = '[VOUCHER] Fetch Vouchers Success',

  FetchVoucherById = '[VOUCHER] Fetch Voucher By Id',
  FetchVoucherByIdSuccess = '[VOUCHER] Fetch Voucher By Id Success',

  FetchVoucherIntents = '[VOUCHER] Fetch Voucher Intents',
  FetchVoucherIntentsSuccess = '[VOUCHER] Fetch Voucher Intents Success',

  CreateVoucher = '[VOUCHER] Create Voucher',
  CreateVoucherSuccess = '[VOUCHER] Create Voucher Success',

  UpdateVoucher = '[VOUCHER] Update Voucher',
  UpdateVoucherSuccess = '[VOUCHER] Update Voucher Success',

  SendVoucher = '[VOUCHER] Send Voucher',
  SendVoucherSuccess = '[VOUCHER] Send Voucher Success',

  ClearCreatedVoucher = '[VOUCHER] Clear Created Voucher',
}

// Fetch Vouchers

export class FetchVouchers implements Action {
  public readonly type = EVoucherActions.FetchVouchers;

  constructor(public search?: string, public next_page?: number) {}
}

export class FetchVouchersSuccess implements Action {
  public readonly type = EVoucherActions.FetchVouchersSuccess;

  constructor(public vouchers: PaginationHolder<IVoucherModel>) {}
}

// Fetch Voucher By Id

export class FetchVoucherById implements Action {
  public readonly type = EVoucherActions.FetchVoucherById;

  constructor(public id: string) {}
}

export class FetchVoucherByIdSuccess implements Action {
  public readonly type = EVoucherActions.FetchVoucherByIdSuccess;

  constructor(public voucher: IVoucherModel) {}
}

// Fetch Voucher Intents

export class FetchVoucherIntents implements Action {
  public readonly type = EVoucherActions.FetchVoucherIntents;

  constructor(public id: string) {}
}

export class FetchVoucherIntentsSuccess implements Action {
  public readonly type = EVoucherActions.FetchVoucherIntentsSuccess;

  constructor(public intents: PaginationHolder<IPaymentIntentModel>) {}
}

// Create Voucher

export class CreateVoucher implements Action {
  public readonly type = EVoucherActions.CreateVoucher;

  constructor(public reqData: ICreateVoucherRequestModel) {}
}

export class CreateVoucherSuccess implements Action {
  public readonly type = EVoucherActions.CreateVoucherSuccess;

  constructor(public voucher: IVoucherModel) {}
}

// Update Voucher

export class UpdateVoucher implements Action {
  public readonly type = EVoucherActions.UpdateVoucher;

  constructor(public id: string, public reqData: IUpdateVoucherRequestModel) {}
}

export class UpdateVoucherSuccess implements Action {
  public readonly type = EVoucherActions.UpdateVoucherSuccess;

  constructor(public voucher: IVoucherModel) {}
}

// Send Voucher

export class SendVoucher implements Action {
  public readonly type = EVoucherActions.SendVoucher;

  constructor(public id: string, public email: string) {}
}

export class SendVoucherSuccess implements Action {
  public readonly type = EVoucherActions.SendVoucherSuccess;

  constructor() {}
}

// Clear Created Voucher

export class ClearCreatedVoucher implements Action {
  public readonly type = EVoucherActions.ClearCreatedVoucher;

  constructor() {}
}


export type VoucherActions =
  FetchVouchers | FetchVouchersSuccess |
  FetchVoucherById | FetchVoucherByIdSuccess |
  FetchVoucherIntents | FetchVoucherIntentsSuccess |
  CreateVoucher | CreateVoucherSuccess |
  UpdateVoucher | UpdateVoucherSuccess |
  SendVoucher | SendVoucherSuccess |
  ClearCreatedVoucher;
