import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Store} from "@ngrx/store";
import {IRootState} from "@core/store/root.state";
import {catchError, mergeMap} from "rxjs/operators";
import {from, of} from "rxjs";
import {LoadError} from "@core/store/error/error.actions";
import {StatisticsService} from "@core/store/statistics/statistics.service";
import {
  EStatisticsActions, FetchChainRevenueChart, FetchChainRevenueChartSuccess, FetchOverviewStatistics, FetchOverviewStatisticsSuccess,
  FetchSMSUsage,
  FetchSMSUsageSuccess,
  GetDashboardRevenue,
  GetDashboardRevenueSuccess
} from "@core/store/statistics/statistics.actions";

@Injectable()
export class StatisticsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<IRootState>,
    private statisticsService: StatisticsService,
  ) {}

  public onGetDashboardRevenue = createEffect(() => this.actions$.pipe(ofType<GetDashboardRevenue>(EStatisticsActions.GetDashboardRevenue),
    mergeMap((action) => from(this.statisticsService.getDashboardRevenue(action.from_date, action.to_date, action.group_by)).pipe(
      mergeMap((data) => [new GetDashboardRevenueSuccess(data)]),
      catchError((error) => of(new LoadError(error, action)))
    ))
  ));

  public onGetSMSUsage = createEffect(() => this.actions$.pipe(ofType<FetchSMSUsage>(EStatisticsActions.FetchSMSUsage),
    mergeMap((action) => from(this.statisticsService.getSMSUsage(action.from_date, action.to_date)).pipe(
      mergeMap((usage) => [new FetchSMSUsageSuccess(usage)]),
      catchError((error) => of(new LoadError(error, action)))
    ))
  ));

  public onFetchOverviewStats = createEffect(() => this.actions$.pipe(ofType<FetchOverviewStatistics>(EStatisticsActions.FetchOverviewStatistics),
    mergeMap((action) => from(this.statisticsService.getOverviewStats(action.from_date, action.to_date, action.group_by)).pipe(
      mergeMap((data) => [new FetchOverviewStatisticsSuccess(data)]),
      catchError((error) => of(new LoadError(error, action)))
    ))
  ));

  public onFetchChainRevenueChart = createEffect(() => this.actions$.pipe(ofType<FetchChainRevenueChart>(EStatisticsActions.FetchChainRevenueChart),
    mergeMap((action) => from(this.statisticsService.getChainRevenue(action.from_date, action.to_date, action.group_by)).pipe(
      mergeMap((data) => [new FetchChainRevenueChartSuccess(data)]),
      catchError((error) => of(new LoadError(error, action)))
    ))
  ));
}
