<div class="select-none rounded-full object-cover" [ngStyle]="{ width: size + 'px', height: size + 'px' }">
  <img
    *ngIf="user?.image_url && user?.image_url?.length > 0"
    class="pointer-events-none h-full w-full rounded-full object-cover"
    [src]="user?.image_url"
    alt="user-image" />
  <div
    *ngIf="!user?.image_url"
    [style.background-color]="user?.primary_color ?? (showInitials ? '#E5E7EB' : '#E5E7EB')"
    class="flex {{ selected ? 'brightness-75' : '' }} w-full h-full items-center justify-center rounded-full">
    <p
      *ngIf="showInitials"
      [style.color]="user?.secondary_color ?? '#374151'"
      class="uppercase font-medium {{ selected ? 'brightness-75' : '' }}
			{{ size === EAvatarSize.EXTRA_SMALL ? 'text-[10px]' : '' }}
			{{ size === EAvatarSize.SMALL ? 'text-sm' : '' }}
			{{ size === EAvatarSize.MEDIUM ? 'text-md' : '' }}
			{{ size === EAvatarSize.BIG ? 'text-3xl' : '' }}">
      {{ user | avatarNameInitials: customer_name }}
    </p>
    <img *ngIf="!showInitials" src="assets/icon/user-single.svg" alt="icon" />
  </div>
</div>
