import {EffectsModule} from "@ngrx/effects";
import {CommonModule} from "@angular/common";
import {StoreModule} from "@ngrx/store";
import {EconomyEffects} from "@core/store/economy/economy.effects";
import {NgModule} from "@angular/core";
import {economyReducers} from "@core/store/economy/economy.reducers";

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('economy', economyReducers),
        EffectsModule.forFeature([EconomyEffects]),
    ],
    providers: [EconomyEffects],
})
export class EconomyStoreModule {
}
