import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-dialog-box',
  templateUrl: './dialog-box.component.html',
  styleUrls: ['./dialog-box.component.scss'],
})
export class DialogBoxComponent implements OnInit {
	@Input() public title: string | undefined;
	@Input() public message: string | undefined;
  @Input() public action: string = 'OK';

  @Input() public showInput = false;
  @Input() public inputPlaceholder = 'Indtast email';
  @Input() public validation: 'email' | 'required' | 'none';

  public inputText: string | undefined;

  public validationForm: FormGroup;

  constructor(
    private _modalCtrl: ModalController,
    private formBuilder: FormBuilder
  ) { }

  public ngOnInit(): void {
    let validators = [];

    if (this.validation === 'email') {
      validators = [Validators.required, Validators.email];
    } else if (this.validation === 'required') {
      validators = [Validators.required];
    }

    this.validationForm = this.formBuilder.group({
      input: ['', validators]
    });
  }

  public submitForm(): void {
    if (this.validationForm.valid) {
      this.inputText = this.validationForm.get('input').value;
    }
  }

	public async cancel(): Promise<void> {
		await this._modalCtrl.dismiss(null, 'cancel');
	}

	public async confirm(): Promise<void> {
    if (this.validationForm.valid) {
      this.inputText = this.validationForm.get('input').value;
    } else {
      return;
    }

    const data = this.showInput ? {input: this.inputText} : null;
		await this._modalCtrl.dismiss(data, 'confirm');
	}

}
