import {TuiCountryIsoCode} from '@taiga-ui/i18n/enums';

export const TUI_DANISH_LANGUAGE_COUNTRIES: Record<TuiCountryIsoCode, string> = {
    [TuiCountryIsoCode.AD]: `Andorra`,
    [TuiCountryIsoCode.AE]: `United Arab Emirates`,
    [TuiCountryIsoCode.AF]: `Afghanistan`,
    [TuiCountryIsoCode.AG]: `Antigua and Barbuda`,
    [TuiCountryIsoCode.BL]: `Saint Barthélemy`,
    [TuiCountryIsoCode.AI]: `Anguilla`,
    [TuiCountryIsoCode.AL]: `Albania`,
    [TuiCountryIsoCode.AM]: `Armenia`,
    [TuiCountryIsoCode.AO]: `Angola`,
    [TuiCountryIsoCode.AR]: `Argentina`,
    [TuiCountryIsoCode.AT]: `Austria`,
    [TuiCountryIsoCode.AU]: `Australia`,
    [TuiCountryIsoCode.AW]: `Aruba`,
    [TuiCountryIsoCode.AZ]: `Azerbaijan`,
    [TuiCountryIsoCode.BA]: `Bosnia and Herzegovina`,
    [TuiCountryIsoCode.BB]: `Barbados`,
    [TuiCountryIsoCode.BD]: `Bangladesh`,
    [TuiCountryIsoCode.BE]: `Belgium`,
    [TuiCountryIsoCode.BF]: `Burkina Faso`,
    [TuiCountryIsoCode.BG]: `Bulgaria`,
    [TuiCountryIsoCode.BH]: `Bahrain`,
    [TuiCountryIsoCode.BI]: `Burundi`,
    [TuiCountryIsoCode.BJ]: `Benin`,
    [TuiCountryIsoCode.GF]: `Guyana`,
    [TuiCountryIsoCode.BM]: `Bermuda`,
    [TuiCountryIsoCode.BN]: `Brunei Darussalam`,
    [TuiCountryIsoCode.BO]: `Bolivia`,
    [TuiCountryIsoCode.BQ]: `Bonaire, Sint Eustatius and Saba`,
    [TuiCountryIsoCode.BR]: `Brazil`,
    [TuiCountryIsoCode.BS]: `Bahamas`,
    [TuiCountryIsoCode.BT]: `Bhutan`,
    [TuiCountryIsoCode.BW]: `Botswana`,
    [TuiCountryIsoCode.BY]: `Belarus`,
    [TuiCountryIsoCode.BZ]: `Belize`,
    [TuiCountryIsoCode.CA]: `Canada`,
    [TuiCountryIsoCode.CD]: `Dem. Rep. Congo (Kinshasa)`,
    [TuiCountryIsoCode.CF]: `Central African Republic`,
    [TuiCountryIsoCode.CG]: `Congo (Brazzaville)`,
    [TuiCountryIsoCode.CH]: `Switzerland`,
    [TuiCountryIsoCode.CI]: `Ivory Coast`,
    [TuiCountryIsoCode.CL]: `Chile`,
    [TuiCountryIsoCode.CM]: `Cameroon`,
    [TuiCountryIsoCode.CN]: `China`,
    [TuiCountryIsoCode.CO]: `Colombia`,
    [TuiCountryIsoCode.CR]: `Costa Rica`,
    [TuiCountryIsoCode.CU]: `Cuba`,
    [TuiCountryIsoCode.CV]: `Cape Verde`,
    [TuiCountryIsoCode.CW]: `Curaçao`,
    [TuiCountryIsoCode.CY]: `Cyprus`,
    [TuiCountryIsoCode.CZ]: `Czech Republic`,
    [TuiCountryIsoCode.DE]: `Germany`,
    [TuiCountryIsoCode.DJ]: `Djibouti`,
    [TuiCountryIsoCode.DK]: `Denmark`,
    [TuiCountryIsoCode.DM]: `Dominica`,
    [TuiCountryIsoCode.DO]: `Dominican Republic`,
    [TuiCountryIsoCode.DZ]: `Algeria`,
    [TuiCountryIsoCode.EC]: `Ecuador`,
    [TuiCountryIsoCode.EE]: `Estonia`,
    [TuiCountryIsoCode.EG]: `Egypt`,
    [TuiCountryIsoCode.ER]: `Eritrea`,
    [TuiCountryIsoCode.ES]: `Spain`,
    [TuiCountryIsoCode.ET]: `Ethiopia`,
    [TuiCountryIsoCode.FI]: `Finland`,
    [TuiCountryIsoCode.FJ]: `Fiji`,
    [TuiCountryIsoCode.FK]: `Falkland Islands`,
    [TuiCountryIsoCode.FM]: `F.S. of Micronesia`,
    [TuiCountryIsoCode.FR]: `France`,
    [TuiCountryIsoCode.GA]: `Gabon`,
    [TuiCountryIsoCode.GB]: `United Kingdom`,
    [TuiCountryIsoCode.GD]: `Grenada`,
    [TuiCountryIsoCode.GE]: `Georgia`,
    [TuiCountryIsoCode.GP]: `Guadeloupe`,
    [TuiCountryIsoCode.GH]: `Ghana`,
    [TuiCountryIsoCode.GI]: `Gibraltar`,
    [TuiCountryIsoCode.GL]: `Greenland`,
    [TuiCountryIsoCode.GM]: `Gambia`,
    [TuiCountryIsoCode.GN]: `Guinea`,
    [TuiCountryIsoCode.MF]: `Saint-Martin`,
    [TuiCountryIsoCode.GQ]: `Equatorial Guinea`,
    [TuiCountryIsoCode.GR]: `Greece`,
    [TuiCountryIsoCode.GT]: `Guatemala`,
    [TuiCountryIsoCode.GW]: `Guinea-Bissau`,
    [TuiCountryIsoCode.GY]: `Guyana`,
    [TuiCountryIsoCode.HK]: `Hong Kong`,
    [TuiCountryIsoCode.HN]: `Honduras`,
    [TuiCountryIsoCode.HR]: `Croatia`,
    [TuiCountryIsoCode.HT]: `Haiti`,
    [TuiCountryIsoCode.HU]: `Hungary`,
    [TuiCountryIsoCode.ID]: `Indonesia`,
    [TuiCountryIsoCode.IE]: `Ireland`,
    [TuiCountryIsoCode.IL]: `Israel`,
    [TuiCountryIsoCode.IN]: `India`,
    [TuiCountryIsoCode.IQ]: `Iraq`,
    [TuiCountryIsoCode.IR]: `Iran`,
    [TuiCountryIsoCode.IS]: `Iceland`,
    [TuiCountryIsoCode.IT]: `Italy`,
    [TuiCountryIsoCode.JM]: `Jamaica`,
    [TuiCountryIsoCode.JO]: `Jordan`,
    [TuiCountryIsoCode.JP]: `Japan`,
    [TuiCountryIsoCode.KE]: `Kenya`,
    [TuiCountryIsoCode.KG]: `Kyrgyzstan`,
    [TuiCountryIsoCode.KH]: `Cambodia`,
    [TuiCountryIsoCode.KM]: `Comoros`,
    [TuiCountryIsoCode.KN]: `Saint Kitts and Nevis`,
    [TuiCountryIsoCode.KP]: `Korean PDR`,
    [TuiCountryIsoCode.KR]: `Rep. Korea`,
    [TuiCountryIsoCode.KW]: `Kuwait`,
    [TuiCountryIsoCode.KY]: `Cayman Islands`,
    [TuiCountryIsoCode.KZ]: `Kazakhstan`,
    [TuiCountryIsoCode.LA]: `Laos`,
    [TuiCountryIsoCode.LB]: `Lebanon`,
    [TuiCountryIsoCode.LC]: `Saint Lucia`,
    [TuiCountryIsoCode.LI]: `Liechtenstein`,
    [TuiCountryIsoCode.LK]: `Sri Lanka`,
    [TuiCountryIsoCode.LR]: `Liberia`,
    [TuiCountryIsoCode.LS]: `Lesotho`,
    [TuiCountryIsoCode.LT]: `Lithuania`,
    [TuiCountryIsoCode.LU]: `Luxembourg`,
    [TuiCountryIsoCode.LV]: `Latvia`,
    [TuiCountryIsoCode.LY]: `Libya`,
    [TuiCountryIsoCode.MA]: `Morocco`,
    [TuiCountryIsoCode.MC]: `Monaco`,
    [TuiCountryIsoCode.MD]: `Moldova`,
    [TuiCountryIsoCode.ME]: `Montenegro`,
    [TuiCountryIsoCode.MQ]: `Martinique`,
    [TuiCountryIsoCode.MG]: `Madagascar`,
    [TuiCountryIsoCode.MK]: `Rep. of North Macedonia`,
    [TuiCountryIsoCode.ML]: `Mali`,
    [TuiCountryIsoCode.MM]: `Burma (Myanmar)`,
    [TuiCountryIsoCode.MN]: `Mongolia`,
    [TuiCountryIsoCode.MO]: `Macau`,
    [TuiCountryIsoCode.RE]: `Réunion`,
    [TuiCountryIsoCode.MR]: `Mauritania`,
    [TuiCountryIsoCode.MS]: `Montserrat`,
    [TuiCountryIsoCode.MT]: `Malta`,
    [TuiCountryIsoCode.MU]: `Mauritius`,
    [TuiCountryIsoCode.MV]: `Maldives`,
    [TuiCountryIsoCode.MW]: `Malawi`,
    [TuiCountryIsoCode.MX]: `Mexico`,
    [TuiCountryIsoCode.MY]: `Malaysia`,
    [TuiCountryIsoCode.MZ]: `Mozambique`,
    [TuiCountryIsoCode.NA]: `Namibia`,
    [TuiCountryIsoCode.NC]: `New Caledonia`,
    [TuiCountryIsoCode.NE]: `Niger`,
    [TuiCountryIsoCode.NG]: `Nigeria`,
    [TuiCountryIsoCode.NI]: `Nicaragua`,
    [TuiCountryIsoCode.NL]: `Netherlands`,
    [TuiCountryIsoCode.NO]: `Norway`,
    [TuiCountryIsoCode.NP]: `Nepal`,
    [TuiCountryIsoCode.NZ]: `New Zealand`,
    [TuiCountryIsoCode.OM]: `Oman`,
    [TuiCountryIsoCode.PA]: `Panama`,
    [TuiCountryIsoCode.PE]: `Peru`,
    [TuiCountryIsoCode.PF]: `French Polynesia (Tahiti)`,
    [TuiCountryIsoCode.PG]: `Papua New Guinea`,
    [TuiCountryIsoCode.PH]: `Philippines`,
    [TuiCountryIsoCode.PK]: `Pakistan`,
    [TuiCountryIsoCode.PL]: `Poland`,
    [TuiCountryIsoCode.PT]: `Portugal`,
    [TuiCountryIsoCode.PW]: `Palau`,
    [TuiCountryIsoCode.PY]: `Paraguay`,
    [TuiCountryIsoCode.QA]: `Qatar`,
    [TuiCountryIsoCode.YT]: `Mayotte`,
    [TuiCountryIsoCode.RO]: `Romania`,
    [TuiCountryIsoCode.RS]: `Serbia`,
    [TuiCountryIsoCode.RU]: `Russia`,
    [TuiCountryIsoCode.RW]: `Rwanda`,
    [TuiCountryIsoCode.SA]: `Saudi Arabia`,
    [TuiCountryIsoCode.SB]: `Solomon Islands`,
    [TuiCountryIsoCode.SC]: `Seychelles`,
    [TuiCountryIsoCode.SD]: `Sudan`,
    [TuiCountryIsoCode.SE]: `Sweden`,
    [TuiCountryIsoCode.SG]: `Singapore`,
    [TuiCountryIsoCode.SH]: `Saint Helena`,
    [TuiCountryIsoCode.SI]: `Slovenia`,
    [TuiCountryIsoCode.SK]: `Slovakia`,
    [TuiCountryIsoCode.SL]: `Sierra Leone`,
    [TuiCountryIsoCode.SM]: `San Marino`,
    [TuiCountryIsoCode.SN]: `Senegal`,
    [TuiCountryIsoCode.SO]: `Somalia`,
    [TuiCountryIsoCode.SR]: `Suriname`,
    [TuiCountryIsoCode.ST]: `Sao Tome and Principe`,
    [TuiCountryIsoCode.SV]: `El Salvador`,
    [TuiCountryIsoCode.SX]: `Sint Maarten`,
    [TuiCountryIsoCode.SY]: `Syrian Arab Republic`,
    [TuiCountryIsoCode.SZ]: `Swaziland`,
    [TuiCountryIsoCode.TC]: `Turks and Caicos`,
    [TuiCountryIsoCode.TD]: `Chad`,
    [TuiCountryIsoCode.TG]: `Togo`,
    [TuiCountryIsoCode.TH]: `Thailand`,
    [TuiCountryIsoCode.TJ]: `Tajikistan`,
    [TuiCountryIsoCode.TL]: `East Timor`,
    [TuiCountryIsoCode.TM]: `Turkmenistan`,
    [TuiCountryIsoCode.TN]: `Tunisia`,
    [TuiCountryIsoCode.TO]: `Tonga`,
    [TuiCountryIsoCode.TR]: `Turkey`,
    [TuiCountryIsoCode.TT]: `Trinidad and Tobago`,
    [TuiCountryIsoCode.TW]: `Taiwan`,
    [TuiCountryIsoCode.TZ]: `Tanzania`,
    [TuiCountryIsoCode.UA]: `Ukraine`,
    [TuiCountryIsoCode.UG]: `Uganda`,
    [TuiCountryIsoCode.US]: `USA`,
    [TuiCountryIsoCode.UY]: `Uruguay`,
    [TuiCountryIsoCode.UZ]: `Uzbekistan`,
    [TuiCountryIsoCode.VC]: `Saint Vincent and the Grenadines`,
    [TuiCountryIsoCode.VE]: `Venezuela`,
    [TuiCountryIsoCode.VG]: `British Virgin Islands`,
    [TuiCountryIsoCode.VN]: `Vietnam`,
    [TuiCountryIsoCode.VU]: `Vanuatu`,
    [TuiCountryIsoCode.WS]: `Samoa`,
    [TuiCountryIsoCode.XK]: `Kosovo`,
    [TuiCountryIsoCode.YE]: `Yemen`,
    [TuiCountryIsoCode.ZA]: `South Africa`,
    [TuiCountryIsoCode.ZM]: `Zambia`,
    [TuiCountryIsoCode.ZW]: `Zimbabwe`,
};
