import {EffectsModule} from "@ngrx/effects";
import {CommonModule} from "@angular/common";
import {StoreModule} from "@ngrx/store";
import {PaymentEffects} from "@core/store/payment/payment.effects";
import {NgModule} from "@angular/core";
import {paymentReducers} from "@core/store/payment/payment.reducers";

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('payment', paymentReducers),
        EffectsModule.forFeature([PaymentEffects]),
    ],
    providers: [PaymentEffects],
})
export class PaymentStoreModule {
}
