import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CredentialService {
  private email: string;
  private password: string;

  public setCredentials(email: string, password: string): void {
    this.email = email;
    this.password = password;
  }

  public getCredentials(): { email: string, password: string } {
    return {email: this.email, password: this.password};
  }

  public clearCredentials(): void {
    this.email = undefined;
    this.password = undefined;
  }
}
