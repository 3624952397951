import produce from 'immer';
import { EErrorActions, ErrorActions } from '@app/core/store/error/error.actions';
import { IAuthenticationState, initialAuthenticationState } from '@app/core/store/authentication/authentication.state';
import { AuthenticationActions, EAuthenticationActions } from '@app/core/store/authentication/authentication.actions';
import { EUserRole } from '@enums/user-permission.enum';

export const authenticationReducers = (
  state: IAuthenticationState = initialAuthenticationState,
  action: AuthenticationActions | ErrorActions
): IAuthenticationState => {
  let nextState: IAuthenticationState;

  switch (action.type) {
    case EAuthenticationActions.CreateAccount:
      nextState = produce(state, draftState => {
        draftState.isLoading = true;
      });

      return nextState;
    case EAuthenticationActions.CreateAccountSuccess:
      nextState = produce(state, draftState => {
        draftState.isLoading = false;
        draftState.user = action.user;
      });

      return nextState;

    // Login

    case EAuthenticationActions.PreAuth:
      nextState = produce(state, draftState => {
        draftState.isLoading = true;
      });

      return nextState;

    case EAuthenticationActions.PreAuthSuccess:
      nextState = produce(state, draftState => {
        draftState.accounts = action.accounts;
      });

      return nextState;

    // Login

    case EAuthenticationActions.Login:
      nextState = produce(state, draftState => {
        draftState.isLoading = true;
      });

      return nextState;

    case EAuthenticationActions.LoginSuccess:
      nextState = produce(state, draftState => {
        draftState.isLoading = false;
        draftState.account = action.account;
        draftState.user = action.account.user;
      });

      return nextState;

    // Login With Token

    case EAuthenticationActions.LoginWithToken:
      nextState = produce(state, draftState => {
        draftState.isLoading = true;
        draftState.user = {
          email: '',
          name: '',
          id: '',
          token: action.token,
          image_url: undefined,
          last_login_at: '',
          primary_color: '',
          secondary_color: '',
          organization_number: '',
          address: undefined,
          role: EUserRole.user,
        };
      });

      return nextState;

    case EAuthenticationActions.LoginWithTokenSuccess:
      nextState = produce(state, draftState => {
        draftState.isLoading = false;
        draftState.account = action.account;
      });

      return nextState;

    // Forgot Password

    case EAuthenticationActions.ForgotPassword:
      nextState = produce(state, draftState => {
        draftState.isLoading = true;
      });

      return nextState;

    case EAuthenticationActions.ForgotPasswordSuccess:
      nextState = produce(state, draftState => {
        draftState.isLoading = false;
      });

      return nextState;

    // Reset Password

    case EAuthenticationActions.ResetPassword:
      nextState = produce(state, draftState => {
        draftState.isLoading = true;
      });

      return nextState;

    case EAuthenticationActions.ResetPasswordSuccess:
      nextState = produce(state, draftState => {
        draftState.isLoading = false;
      });

      return nextState;

    // Fetch Users

    case EAuthenticationActions.FetchUsers:
      nextState = produce(state, draftState => {
        draftState.isLoading = true;
      });

      return nextState;

    case EAuthenticationActions.FetchUsersSuccess:
      nextState = produce(state, draftState => {
        draftState.isLoading = false;
        draftState.users = action.users.data;
        draftState.usersCursor = action.users;
      });

      return nextState;

    // Create User

    case EAuthenticationActions.CreateUser:
      nextState = produce(state, draftState => {
        draftState.isLoading = true;
      });

      return nextState;

    case EAuthenticationActions.CreateUserSuccess:
      nextState = produce(state, draftState => {
        draftState.isLoading = false;
      });

      return nextState;

    // Update User

    case EAuthenticationActions.UpdateUser:
    case EAuthenticationActions.UpdateUserWithImage:
      nextState = produce(state, draftState => {
        draftState.isLoading = true;
      });

      return nextState;

    case EAuthenticationActions.UpdateUserSuccess:
    case EAuthenticationActions.UpdateUserWithImageSuccess:
      nextState = produce(state, draftState => {
        draftState.isLoading = false;
      });

      return nextState;

    // Fetch Account

    case EAuthenticationActions.FetchAccount:
      nextState = produce(state, draftState => {
        draftState.isLoading = true;
      });

      return nextState;

    case EAuthenticationActions.FetchAccountSuccess:
      nextState = produce(state, draftState => {
        draftState.isLoading = false;
        draftState.account = action.account;
      });

      return nextState;

    // Fetch Account Settings

    case EAuthenticationActions.FetchAccountSettings:
      nextState = produce(state, draftState => {
        draftState.isLoading = true;
      });

      return nextState;

    case EAuthenticationActions.FetchAccountSettingsSuccess:
      nextState = produce(state, draftState => {
        draftState.isLoading = false;
        draftState.settings = action.settings;
      });

      return nextState;

    // Update Account Settings

    case EAuthenticationActions.UpdateAccountSettings:
      nextState = produce(state, draftState => {
        draftState.isLoading = true;
      });

      return nextState;

    case EAuthenticationActions.UpdateAccountSettingsSuccess:
      nextState = produce(state, draftState => {
        draftState.isLoading = false;
        draftState.settings = action.settings;
      });

      return nextState;

    // Patch Account

    case EAuthenticationActions.UpdateAccount:
      nextState = produce(state, draftState => {
        draftState.isLoading = true;
      });

      return nextState;

    case EAuthenticationActions.UpdateAccountSuccess:
      nextState = produce(state, draftState => {
        draftState.isLoading = false;
      });

      return nextState;

    // Update Onboarding Status

    case EAuthenticationActions.UpdateOnboardingStatus:
      nextState = produce(state, draftState => {
        if (action.settings) {
          draftState.isOnboardingSettingsCompleted = action.settings;
        }

        if (action.link) {
          draftState.isOnboardingLinkCompleted = action.link;
        }

        if (action.completed) {
          draftState.isOnboardingCompleted = action.completed;
        }
      });

      return nextState;

    // ERROR

    case EErrorActions.LoadError:
      nextState = produce(state, draftState => {
        draftState.isLoading = false;
      });

      return nextState;
    default:
      return state;
  }
};
