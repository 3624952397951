import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {UserAvatarComponent} from "@app/shared/components/user-avatar/user-avatar.component";
import {AvatarNameInitialsModule} from "@app/shared/pipes/avatar-name-initials/avatar-name-initials.module";

@NgModule({
	declarations: [UserAvatarComponent],
    imports: [CommonModule, AvatarNameInitialsModule],
	exports: [UserAvatarComponent],
})
export class UserAvatarModule {}
