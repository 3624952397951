import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import {authenticationReducers} from "@app/core/store/authentication/authentication.reducers";
import {AuthenticationEffects} from "@app/core/store/authentication/authentication.effects";

@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature('authentication', authenticationReducers),
		EffectsModule.forFeature([AuthenticationEffects])],
	providers: [AuthenticationEffects],
})
export class AuthenticationStoreModule {}
