import {IRootState} from "@core/store/root.state";
import {createSelector} from "@ngrx/store";
import {ICatalogState} from "@core/store/catalog/catalog.state";

const _selectCatalog = (state: IRootState) => state.catalog;

export const selectCatalogIsLoading = createSelector(_selectCatalog, (state: ICatalogState) => state.isLoading);
export const selectCategories = createSelector(_selectCatalog, (state: ICatalogState) => state.categories);
export const selectProducts = createSelector(_selectCatalog, (state: ICatalogState) => state.products);
export const selectCategoryProducts = createSelector(_selectCatalog, (state: ICatalogState) => state.categoryProducts);
export const selectSelectedCategoryId = createSelector(_selectCatalog, (state: ICatalogState) => state.selectedCategoryId);
