import {Component, OnInit} from '@angular/core';
import {LanguageService} from "@services/language/language.service";
import {IRootState} from "@core/store/root.state";
import {Store} from "@ngrx/store";
import {Language} from "@services/language/language-base.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	constructor(
		private _languageService: LanguageService,
		private _store: Store<IRootState>
	) {}

	public ngOnInit(): void {
		this.setupLanguage();
	}

	private setupLanguage(): void {
		const browserLang: string = navigator.language;

		const existingLanguage: Language = this._languageService
			.getSupportedLanguages()
			.find((lang: Language) => lang.browserMatch.find((b) => b.toLowerCase() === browserLang.toLowerCase()));

		!!existingLanguage
			? this._languageService.setLanguage(existingLanguage)
			: this._languageService.setLanguage(this._languageService.getDefaultLanguage());
	}
}
