import produce from "immer";
import {EErrorActions, ErrorActions} from "@app/core/store/error/error.actions";
import {IVoucherState, initialVoucherState} from "@app/core/store/voucher/voucher.state";
import {VoucherActions, EVoucherActions} from "@app/core/store/voucher/voucher.actions";

export const voucherReducers = (state: IVoucherState = initialVoucherState, action: VoucherActions | ErrorActions): IVoucherState => {
  let nextState;

  switch (action.type) {

    // Fetch Vouchers
    case EVoucherActions.FetchVouchers:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = true;
      });

      return nextState;
    case EVoucherActions.FetchVouchersSuccess:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = false;

        if (action.vouchers.meta.current_page > 1) {
          if (!draftState.vouchers?.data) {
            draftState.vouchers = action.vouchers;
          } else {
            draftState.vouchers.data = draftState.vouchers.data.concat(action.vouchers.data);
            draftState.vouchers.meta = action.vouchers.meta;
          }
        } else {
          draftState.vouchers = action.vouchers;
        }
      });

      return nextState;

    // Fetch Voucher By Id
    case EVoucherActions.FetchVoucherById:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = true;
      });

      return nextState;
    case EVoucherActions.FetchVoucherByIdSuccess:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = false;
        draftState.voucher = action.voucher;
      });

      return nextState;

    // Fetch Voucher Intents
    case EVoucherActions.FetchVoucherIntents:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = true;
      });

      return nextState;
    case EVoucherActions.FetchVoucherIntentsSuccess:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = false;
        draftState.voucherIntents = action.intents;
      });

      return nextState;

    // Create Voucher
    case EVoucherActions.CreateVoucher:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = true;
      });

      return nextState;
    case EVoucherActions.CreateVoucherSuccess:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = false;
        draftState.createdVoucher = action.voucher;
      });

      return nextState;

    // Update Voucher
    case EVoucherActions.UpdateVoucher:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = true;
      });

      return nextState;
    case EVoucherActions.UpdateVoucherSuccess:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = false;
        const foundIndex = state.vouchers.data.findIndex(v => v.id === action.voucher.id);

        if (foundIndex !== -1) {
          draftState.vouchers[foundIndex] = action.voucher;
        }

        if (state.voucher.id === action.voucher.id) {
          draftState.voucher = action.voucher
        }
      });

      return nextState;

    // Send Voucher
    case EVoucherActions.SendVoucher:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = true;
      });

      return nextState;
    case EVoucherActions.SendVoucherSuccess:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = false;
      });

      return nextState;

    // Clear Created Voucher
    case EVoucherActions.ClearCreatedVoucher:
      nextState = produce(state, (draftState) => {
        draftState.createdVoucher = undefined;
      });

      return nextState;

    // ERROR

    case EErrorActions.LoadError:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = false;
      });

      return nextState;
    default:
      return state;
  }
};

