import { Action } from '@ngrx/store';

export enum ENavigationActions {
  NavigateTo = '[Navigation] Navigate To Some Path',
	SetIsSidebarExpanded = '[Navigation] Set Is Sidebar Expanded',
  SetIsAdminMode = '[Navigation] Set Is Admin Mode',
}

export class NavigateTo implements Action {
	public readonly type = ENavigationActions.NavigateTo;

	constructor(public commands: [any], public queryParams?: object) {}
}

export class SetIsSidebarExpanded implements Action {
	public readonly type = ENavigationActions.SetIsSidebarExpanded;

	constructor(public isExpanded: boolean) {}
}

export class SetIsAdminMode implements Action {
  public readonly type = ENavigationActions.SetIsAdminMode;

  constructor(public isAdminMode: boolean) {}
}

export type NavigationActions =
	| NavigateTo
	| SetIsSidebarExpanded
  | SetIsAdminMode;
