import { NgModule } from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {PortalMenuComponent} from "@app/modules/portal/components/portal-menu/portal-menu.component";
import {RoleGuard} from "@core/guards/role.guard";

const routes: Routes = [
	{
		path: '',
		pathMatch: 'full',
		redirectTo: 'dashboard',
	},
	{
		path: '',
		loadChildren: () =>
			import('@app/modules/public/public.module').then((m) => m.PublicModule),
	},
	{
		path: 'portal',
		loadChildren: () =>
			import('@app/modules/portal/portal.module').then((m) => m.PortalModule),
	},

	{
		path: 'portal/settings',
		component: PortalMenuComponent,
    canActivate: [
      RoleGuard,
    ],
		loadChildren: () =>
			import('@app/modules/portal/settings/settings.module').then((m) => m.SettingsModule),
	},
  {
    path: 'portal/economy',
    component: PortalMenuComponent,
    canActivate: [
      RoleGuard,
    ],
    loadChildren: () =>
      import('@app/modules/portal/pages/economy/economy.module').then((m) => m.EconomyModule),
  },
  {
    path: 'portal/statistics',
    component: PortalMenuComponent,
    canActivate: [
      RoleGuard,
    ],
    loadChildren: () =>
      import('@app/modules/portal/pages/statistics/statistics.module').then((m) => m.StatisticsModule),
  },
];

@NgModule({
  imports: [
		RouterModule.forRoot(routes, {
			useHash: false,
			scrollPositionRestoration: 'top',
			anchorScrolling: 'enabled',
			preloadingStrategy: PreloadAllModules,
		}),
	],
  exports: [RouterModule]
})
export class AppRoutingModule { }
