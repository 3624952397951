import { Action } from '@ngrx/store';
import { IBillingSettingsModel } from '@models/billing-settings.model';
import { IInvoiceModel } from '@models/invoice.model';

export enum EBillingActions {
  AddCreditCardFromToken = '[BILLING] Add Credit Card From Token',
  AddCreditCardFromTokenSuccess = '[BILLING] Add Credit Card From Token Success',

  FetchBillingSettings = '[BILLING] Fetch Billing Settings',
  FetchBillingSettingsSuccess = '[BILLING] Fetch Billing Settings Success',

  FetchInvoices = '[BILLING] Fetch Invoices',
  FetchInvoicesSuccess = '[BILLING] Fetch Invoices Success',
}

// Add Credit Card From Token

export class AddCreditCardFromToken implements Action {
  public readonly type = EBillingActions.AddCreditCardFromToken;

  constructor(public token: string) {}
}

export class AddCreditCardFromTokenSuccess implements Action {
  public readonly type = EBillingActions.AddCreditCardFromTokenSuccess;

  constructor() {}
}

// Fetch Billing Settings

export class FetchBillingSettings implements Action {
  public readonly type = EBillingActions.FetchBillingSettings;

  constructor() {}
}

export class FetchBillingSettingsSuccess implements Action {
  public readonly type = EBillingActions.FetchBillingSettingsSuccess;

  constructor(public settings: IBillingSettingsModel) {}
}

// Fetch Invoices

export class FetchInvoices implements Action {
  public readonly type = EBillingActions.FetchInvoices;

  constructor() {}
}

export class FetchInvoicesSuccess implements Action {
  public readonly type = EBillingActions.FetchInvoicesSuccess;

  constructor(public invoices: IInvoiceModel[]) {}
}

export type BillingActions =
  | AddCreditCardFromToken
  | AddCreditCardFromTokenSuccess
  | FetchBillingSettings
  | FetchBillingSettingsSuccess
  | FetchInvoices
  | FetchInvoicesSuccess;
