import {IUserModel} from "@models/user.model";
import {ICalendarModel} from "@models/calendar.model";
import {IBookingSettingsModel} from "@models/booking-settings.model";
import {ICalendarBookingModel} from "@models/calendar-booking.model";
import {PaginationHolder} from "@models/pagination.model";
import {IBookingOverlapModel} from "@models/booking-overlap.model";
import {IWaitlistModel} from "@models/waitlist.model";

export interface IBookingState {
	isLoading: boolean;
  isLoadingOverlap: boolean;
  isWeekView: boolean;
	employees: IUserModel[];
	calendar: ICalendarModel[];
	currentDate: Date;
  settings: IBookingSettingsModel;
  currentBooking: ICalendarBookingModel;
  overlap?: IBookingOverlapModel;
  customerBookings?: PaginationHolder<ICalendarBookingModel>;
  selectedCalendarUser?: IUserModel;
  waitlist?: PaginationHolder<IWaitlistModel>;
}

export const initialBookingState: IBookingState = {
	isLoading: false,
  isLoadingOverlap: false,
  isWeekView: false,
	employees: [],
	calendar: [],
	currentDate: new Date(),
  settings: null,
  currentBooking: null,
  overlap: undefined,
}
