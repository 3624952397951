import {Action} from "@ngrx/store";
import {IReportModel} from "@models/report.model";
import {PaginationHolder} from "@models/pagination.model";
import {IPaymentMethodModel} from "@models/payment-method.model";
import {ISaleModel} from "@models/sale.model";

export enum EEconomyActions {
    FetchReport = '[ECONOMY] Fetch Report',
    FetchReportSuccess = '[ECONOMY] Fetch Report Success',

    FetchSales = '[ECONOMY] Fetch Sales',
    FetchSalesSuccess = '[ECONOMY] Fetch Sales Success',

    FetchSaleById = '[ECONOMY] Fetch Sale By Id',
    FetchSaleByIdSuccess = '[ECONOMY] Fetch Sale By Id Success',

    FetchPaymentMethods = '[ECONOMY] Fetch Payment Methods',
    FetchPaymentMethodsSuccess = '[ECONOMY] Fetch Payment Methods Success',

    CreatePaymentMethod = '[ECONOMY] Create Payment Method',
    CreatePaymentMethodSuccess = '[ECONOMY] Create Payment Method Success',

    EditPaymentMethod = '[ECONOMY] Edit Payment Method',
    EditPaymentMethodSuccess = '[ECONOMY] Edit Payment Method Success',

    DeletePaymentMethod = '[ECONOMY] Delete Payment Method',
    DeletePaymentMethodSuccess = '[ECONOMY] Delete Payment Method Success',
}

// Fetch Report

export class FetchReport implements Action {
  public readonly type = EEconomyActions.FetchReport;

  constructor(public from_date: string, public to_date: string) {}
}

export class FetchReportSuccess implements Action {
  public readonly type = EEconomyActions.FetchReportSuccess;

  constructor(public report: IReportModel) {}
}

// Fetch Sales

export class FetchSales implements Action {
  public readonly type = EEconomyActions.FetchSales;

  constructor(public from_date: string, public to_date: string, public next_page?: number) {}
}

export class FetchSalesSuccess implements Action {
  public readonly type = EEconomyActions.FetchSalesSuccess;

  constructor(public sales: PaginationHolder<ISaleModel>) {}
}

// Fetch Sale By Id

export class FetchSaleById implements Action {
  public readonly type = EEconomyActions.FetchSaleById;

  constructor(public id: string) {}
}

export class FetchSaleByIdSuccess implements Action {
  public readonly type = EEconomyActions.FetchSaleByIdSuccess;

  constructor(public sale: ISaleModel) {}
}

// Fetch Payment Methods

export class FetchPaymentMethods implements Action {
  public readonly type = EEconomyActions.FetchPaymentMethods;

  constructor() {}
}

export class FetchPaymentMethodsSuccess implements Action {
  public readonly type = EEconomyActions.FetchPaymentMethodsSuccess;

  constructor(public paymentMethods: PaginationHolder<IPaymentMethodModel>) {}
}

// Create Payment Method

export class CreatePaymentMethod implements Action {
  public readonly type = EEconomyActions.CreatePaymentMethod;

  constructor(public name: string, public paymentType: string) {}
}

export class CreatePaymentMethodSuccess implements Action {
  public readonly type = EEconomyActions.CreatePaymentMethodSuccess;

  constructor() {}
}

// Create Payment Method

export class EditPaymentMethod implements Action {
  public readonly type = EEconomyActions.EditPaymentMethod;

  constructor(public id: string, public name: string, public paymentType: string) {}
}

export class EditPaymentMethodSuccess implements Action {
  public readonly type = EEconomyActions.EditPaymentMethodSuccess;

  constructor() {}
}

// Delete Payment Method

export class DeletePaymentMethod implements Action {
  public readonly type = EEconomyActions.DeletePaymentMethod;

  constructor(public id: string) {}
}

export class DeletePaymentMethodSuccess implements Action {
  public readonly type = EEconomyActions.DeletePaymentMethodSuccess;

  constructor() {}
}

export type EconomyActions =
  FetchReport | FetchReportSuccess |
  FetchSales | FetchSalesSuccess |
  FetchSaleById | FetchSaleByIdSuccess |
  FetchPaymentMethods | FetchPaymentMethodsSuccess |
  CreatePaymentMethod | CreatePaymentMethodSuccess |
  EditPaymentMethod | EditPaymentMethodSuccess |
  DeletePaymentMethod | DeletePaymentMethodSuccess;
