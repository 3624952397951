import { Injectable } from '@angular/core';
import {environment} from "@env/environment";
import {IAccountModel} from "@models/account.model";
import Pusher, {Channel} from "pusher-js";

@Injectable({
  providedIn: 'root'
})
export class PusherService {
  public pusher: Pusher;
  public channel: Channel;

  constructor() {
    this.pusher = new Pusher(environment.pusher.key, {
      cluster: environment.pusher.cluster,
    });
  }

  public initPusher(account: IAccountModel): void {
    this.channel = this.pusher.subscribe(`portal-account-${account.id}`);
  }
}
