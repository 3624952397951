import { Action } from '@ngrx/store';
import { ICreateCustomerRequestModel } from '@models/request/create-customer.request.model';
import { ICustomerModel } from '@models/customer.model';
import { PaginationHolder } from '@models/pagination.model';
import { IUpdateCustomerRequestModel } from '@models/request/update-customer.request.model';
import { ICreateCustomerJournalRequestModel } from '@models/request/create-customer-journal.request';
import { IJournalEntryModel } from '@models/journal-entry.model';
import { ICustomerSMSHistoryModel } from '@models/customer-sms-history.model';

export enum ECustomerActions {
  FetchCustomers = '[CUSTOMER] Fetch Customers',
  FetchCustomersSuccess = '[CUSTOMER] Fetch Customers Success',

  CreateCustomer = '[CUSTOMER] Create Customer',
  CreateCustomerSuccess = '[CUSTOMER] Create Customer Success',

  UpdateCustomer = '[CUSTOMER] Update Customer',
  UpdateCustomerSuccess = '[CUSTOMER] Update Customer Success',

  FetchCustomerById = '[CUSTOMER] Fetch Customer By Id',
  FetchCustomerByIdSuccess = '[CUSTOMER] Fetch Customer By Id Success',

  FetchCustomerJournal = '[CUSTOMER] Fetch Customer Journal',
  FetchCustomerJournalSuccess = '[CUSTOMER] Fetch Customer Journal Success',

  CreateCustomerJournal = '[CUSTOMER] Create Customer Journal',
  CreateCustomerJournalSuccess = '[CUSTOMER] Create Customer Journal Success',

  FetchCustomerSMSHistory = '[CUSTOMER] Fetch Customer SMS History',
  FetchCustomerSMSHistorySuccess = '[CUSTOMER] Fetch Customer SMS History Success',

  SendCustomerSMS = '[CUSTOMER] Send Customer SMS',
  SendCustomerSMSSuccess = '[CUSTOMER] Send Customer SMS Success',
}

// Fetch Customer

export class FetchCustomers implements Action {
  public readonly type = ECustomerActions.FetchCustomers;

  constructor(
    public next_page?: number,
    public search?: string
  ) {}
}

export class FetchCustomersSuccess implements Action {
  public readonly type = ECustomerActions.FetchCustomersSuccess;

  constructor(public customers: PaginationHolder<ICustomerModel>) {}
}

// Create Customer

export class CreateCustomer implements Action {
  public readonly type = ECustomerActions.CreateCustomer;

  constructor(public reqModel: ICreateCustomerRequestModel) {}
}

export class CreateCustomerSuccess implements Action {
  public readonly type = ECustomerActions.CreateCustomerSuccess;

  constructor(public customer: ICustomerModel) {}
}

// Update Customer

export class UpdateCustomer implements Action {
  public readonly type = ECustomerActions.UpdateCustomer;

  constructor(
    public id: string,
    public reqModel: IUpdateCustomerRequestModel
  ) {}
}

export class UpdateCustomerSuccess implements Action {
  public readonly type = ECustomerActions.UpdateCustomerSuccess;

  constructor(public customer: ICustomerModel) {}
}

// Fetch Customer By Id

export class FetchCustomerById implements Action {
  public readonly type = ECustomerActions.FetchCustomerById;

  constructor(public id: string) {}
}

export class FetchCustomerByIdSuccess implements Action {
  public readonly type = ECustomerActions.FetchCustomerByIdSuccess;

  constructor(public customer: ICustomerModel) {}
}

// Fetch Customer Journal

export class FetchCustomerJournal implements Action {
  public readonly type = ECustomerActions.FetchCustomerJournal;

  constructor(public id: string) {}
}

export class FetchCustomerJournalSuccess implements Action {
  public readonly type = ECustomerActions.FetchCustomerJournalSuccess;

  constructor(public journal: PaginationHolder<IJournalEntryModel>) {}
}

// Create Customer Journal

export class CreateCustomerJournal implements Action {
  public readonly type = ECustomerActions.CreateCustomerJournal;

  constructor(
    public id: string,
    public reqData: ICreateCustomerJournalRequestModel
  ) {}
}

export class CreateCustomerJournalSuccess implements Action {
  public readonly type = ECustomerActions.CreateCustomerJournalSuccess;

  constructor() {}
}

// Fetch Customer SMS History

export class FetchCustomerSMSHistory implements Action {
  public readonly type = ECustomerActions.FetchCustomerSMSHistory;

  constructor(public id: string) {}
}

export class FetchCustomerSMSHistorySuccess implements Action {
  public readonly type = ECustomerActions.FetchCustomerSMSHistorySuccess;

  constructor(public data: PaginationHolder<ICustomerSMSHistoryModel>) {}
}

// Send Customer SMS

export class SendCustomerSMS implements Action {
  public readonly type = ECustomerActions.SendCustomerSMS;

  constructor(
    public id: string,
    public text: string
  ) {}
}

export class SendCustomerSMSSuccess implements Action {
  public readonly type = ECustomerActions.SendCustomerSMSSuccess;

  constructor() {}
}

export type CustomerActions =
  | FetchCustomers
  | FetchCustomersSuccess
  | CreateCustomer
  | CreateCustomerSuccess
  | UpdateCustomer
  | UpdateCustomerSuccess
  | FetchCustomerById
  | FetchCustomerByIdSuccess
  | FetchCustomerJournal
  | FetchCustomerJournalSuccess
  | CreateCustomerJournal
  | CreateCustomerJournalSuccess
  | FetchCustomerSMSHistory
  | FetchCustomerSMSHistorySuccess
  | SendCustomerSMS
  | SendCustomerSMSSuccess;
